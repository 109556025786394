import {select, put, takeEvery, fork} from 'redux-saga/effects'

import {SECRET_DRAFT_ID} from '../../utils'
import * as Actions from '../actions'
import * as sel from '../selectors'
import {restartOnConfigChange} from './utils'

export default function* localDataSaga() {
  yield* restartOnConfigChange(fetchLocalData)

  yield fork(clearDraftAfterDeploy)

  const actionTypes = [
    Actions.deployContract.type,
    Actions.updateContractData.type,
    Actions.activateContract.type,
    Actions.activateContractFailed.type,
    Actions.checkIn.type,
    Actions.checkInFinished.type,
  ]

  yield takeEvery(actionTypes, updateLocalData)
}

function* fetchLocalData() {
  const localStorageKey = yield* getLocalStorageKey()
  if (!localStorageKey) {
    return
  }
  try {
    const data = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
    yield put(Actions.localDataFetched(data))
  } catch (err) {
    console.error(`Failed to parse local contracts data: ${err.message}`)
  }
}

function* updateLocalData(action) {
  const localStorageKey = yield* getLocalStorageKey()
  if (!localStorageKey) {
    return
  }
  const localData = yield select(state => state.localDataByContractId)
  const serializedData = JSON.stringify(localData)
  try {
    localStorage.setItem(localStorageKey, serializedData)
  } catch (err) {
    console.error(`Failed to save local contracts data: ${err.message}`)
  }
}

const LOCAL_STORAGE_KEY_PREFIX = 'secretsLocalData.'

function* getLocalStorageKey() {
  const {account, netId} = yield select(sel.connectionState)
  return account && (LOCAL_STORAGE_KEY_PREFIX + account + '.' + netId)
}

function* clearDraftAfterDeploy() {
  yield takeEvery(Actions.deployContract.type, function*() {
    const cleanDraft = {description: '', secret: '', recipientPublicKey: '', numKeepers: '', checkInInterval: ''}
    yield put(Actions.updateContractData(SECRET_DRAFT_ID, cleanDraft))
  })
}
