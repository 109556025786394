import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {createLogger} from 'redux-logger'

import * as Actions from './actions'
import * as reducers from './reducers'
import rootSaga from './sagas'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()

const logger = createLogger({
  collapsed: (getState, action) => action.type === Actions.setContracts.type,
})

const store = createStore(
  combineReducers(reducers),
  composeEnhancers(applyMiddleware(sagaMiddleware), applyMiddleware(logger)),
)

sagaMiddleware.run(rootSaga)

export default store
