import React from 'react'
import PropTypes from 'prop-types'
import {useSelector, useDispatch} from 'react-redux'
import styled, { css } from 'styled-components'

import {Actions, sel} from '../redux'
import {SecretState} from '../constants'
import {formatWei} from '../utils'
import styles from './styles'

import Button from './button'
import ProgressBar from './progress-bar'
import NoSecretsWidget from './no-secrets-widget'
import Spinner from './spinner'
import SmallSpinner from './small-spinner'
import StateNames from './utils/state-names'

const h3Typography = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.2px;
`

const textTypography = css`
  font-family: 'Rubic', Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

const labelTypography = css`
  font-family: 'Rubic', Roboto, sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.1px;
`

const H3 = styled.h3`
  ${h3Typography}
  color: #282B43
`

const SecretsListWrapper = styled.div`
  & > .spinner {
    display: block;
    margin: 40px auto 0;
  }
`

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`

const Table = styled.table`
  margin-top: 24px;
  width: 100%;
`

const THead = styled.thead`
  background-color: #FAFBFC;
`

const Th = styled.th`
  ${labelTypography}

  line-height: 40px;
  text-align: left;

  text-transform: uppercase;

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: 16px;
  }

  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding-right: 16px;
    text-align: right;
  }
`

const Cell = styled.td`
  height: 80px;
  width: 220px;
  vertical-align: top;

  padding-left: 4px;
  padding-right: 4px;

  border-bottom: 1px solid #F3F3F3;

  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
    text-align: right;
  }
`

const LastCell = styled(Cell)`
  width: 100px;
`

const CellWrapper = styled.div`
  height: 81px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
`

const Top = styled.div`
  ${textTypography}
  color: #282B43;
  max-width: 192px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  &.warning {
    overflow: visible;
    &:before {
      content: '';
      position: absolute;
      left: -12px;
      top: 8px;
      width: 6px;
      height: 6px;
      background-color: ${styles.colors.error};
      border-radius: 50%;
    }
  }
`

const Bottom = styled.div`
  ${textTypography}
  font-size: 12px;
  color: #8D9CAD;
  max-width: 192px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const SecretId = styled.div`
  max-width: 100%;
  overflow: hidden;
`

const SpinnerWrapper = styled.div`
  width: auto;
  text-align: center;
`

export default function SecretsList({history}) {
  const secrets = useSelector(sel.secrets)
  const dispatch = useDispatch()

  return (
    <SecretsListWrapper>
      <HeaderWrapper>
        <H3>Your Secrets{secrets ? ` (${secrets.length})` : ''}</H3>
        <Button mode='hero' onClick={_=>history.push('/new-secret')}>+ Deploy New Secret</Button>
      </HeaderWrapper>
      {secrets && <Table>
        <THead>
          <tr>
            <Th>Secrets</Th>
            <Th>Status</Th>
            <Th>Check-in Date</Th>
            <Th>Payment</Th>
            <Th>Keepers</Th>
            <Th>Actions</Th>
          </tr>
        </THead>
        <tbody>
          {secrets && secrets.map(secret => renderSecret(secret, dispatch, history.push))}
        </tbody>
      </Table>}
      {secrets && secrets.length === 0 && <NoSecretsWidget />}
      {!secrets && <Spinner className='spinner' />}
    </SecretsListWrapper>
  )
}

SecretsList.propTypes = {
  secrets: PropTypes.arrayOf(PropTypes.object),
}

function formatEther(bn) {
  if (bn) {
    return formatWei(bn)
  } else {
    return '-'
  }
}

function formatDate(timestamp) {
  if (timestamp) {
    const d = new Date(timestamp * 1000)
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    const year = d.getFullYear()
    const month = months[d.getMonth()]
    const date = d.getDate()

    const hour = d.getHours()
    const minute = leftPadWithZeros(d.getMinutes())

    return `${date} ${month} ${year} ${hour}:${minute}`
  } else {
    return '—'
  }
}

function renderKeeperStatusCell(secret) {
  if (secret.state === SecretState.Deploying) {
    return (
      <CellWrapper><Top>—</Top></CellWrapper>
    )
  } else if (secret.state === SecretState.CallForKeepers) {
    const numKeepers = secret.localData.numKeepers|0
    const barValue = Math.min(secret.proposals.length, numKeepers)
    return (
      <CellWrapper>
        <Top>{`${secret.proposals.length} joined`}</Top>
        <ProgressBar color='#3D6FEF' value={barValue} total={numKeepers} />
      </CellWrapper>
    )
  } else if (secret.state === SecretState.CallForKeys) {
    const totalKeysSubmitted = secret.keepers
      .reduce((totalKeys, keeper) => (totalKeys|0) + (keeper.keyPartSupplied ? 1 : 0), 0)
    return (
      <CellWrapper>
        <Top>{`${totalKeysSubmitted}/${secret.keepers.length} received`}</Top>
        <ProgressBar color='#AAAAAA' value={totalKeysSubmitted} total={secret.keepers.length} />
      </CellWrapper>
    )
  } else {
    return (
      <CellWrapper>
        <Top>{`${secret.numReliableKeepers}/${secret.keepers.length} active`}</Top>
        <ProgressBar value={secret.numReliableKeepers} total={secret.keepers.length} />
      </CellWrapper>
    )
  }
}

function renderButtonIfNeeded(secret, dispatch, setLocation) {
  const {state} = secret
  const isNew = state === SecretState.Deploying || state === SecretState.CallForKeepers
  const isActive = state === SecretState.Active
  const isActivating = state === SecretState.Activating

  if (isActive) {
    const isCheckingIn = isActive && !!secret.localData.checkingIn
    return (
      <CellWrapper>
        <Button
          loading={isCheckingIn}
          disabled={isCheckingIn}
          onClick={() => dispatch(Actions.checkIn(secret.id))}>Check In</Button>
      </CellWrapper>
    )
  }

  if (isActivating) {
    return (
      <CellWrapper>
        <SpinnerWrapper>
          <SmallSpinner color={styles.colors.secondaryText} scale={0.5} strokeWidth='3px'/>
        </SpinnerWrapper>
      </CellWrapper>
    )
  }

  if (isNew) {
    return (
      <CellWrapper>
        <Button onClick={_=>setLocation(`/secret/${secret.id}`)}>
          Continue Deploy
        </Button>
      </CellWrapper>
    )
  }

  return (<CellWrapper></CellWrapper>)
}

function renderSecret(secret, dispatch, setLocation) {
  const {state} = secret
  const isNew = state === SecretState.Deploying || state === SecretState.CallForKeepers
  const isActive = state === SecretState.Active
  return (
    <tr key={secret.id}>
      <Cell>
        <CellWrapper>
          <Top className={shouldWarn(secret) ? 'warning' : ''}>
            <SecretId>{secret.id}</SecretId>
          </Top>
          <Bottom>{secret.localData.description}</Bottom>
        </CellWrapper>
      </Cell>
      <Cell>
        <CellWrapper>
          <Top>{StateNames[state]}</Top>
        </CellWrapper>
      </Cell>
      <Cell>
        <CellWrapper>
          <Top>{isNew
            ? '—'
            : isActive
              ? formatDate(secret.lastOwnerCheckInAt + secret.checkInInterval)
              : formatDate(secret.lastCheckin)}
          </Top>
          <Bottom>
            {`${isActive ? 'Next' : 'Last'} check-in`}
          </Bottom>
        </CellWrapper>
      </Cell>
      <Cell>
        <CellWrapper>
          <Top>{isActive
            ? formatEther(secret.checkInPrice)
            : '—'}
          </Top>
          <Bottom>
            Check-in cost
          </Bottom>
        </CellWrapper>
      </Cell>
      <Cell>
        {renderKeeperStatusCell(secret)}
      </Cell>
      <LastCell>
        {renderButtonIfNeeded(secret, dispatch, setLocation)}
      </LastCell>
    </tr>
  )
}

function shouldWarn({lastOwnerCheckInAt, checkInInterval, state}, now = Date.now()) {
  const isActive = state === SecretState.Active
  const checkInInADay = (now - (lastOwnerCheckInAt+checkInInterval)) / 1000 > 86400
  //24 hours * 60 minutes * 60 seconds = 86400 seconds in a day
  return isActive && checkInInADay
}

function leftPadWithZeros(number) {
  return `${number < 10 ? '0' : ''}${number}`
}
