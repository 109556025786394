import {call, spawn, put, takeEvery, delay, select} from 'redux-saga/effects'
import {fetchOwnerContracts} from 'bitcannery-core'

import * as Actions from '../actions'
import {getConnectionError} from '../../utils'

import {restartOnConfigChange} from './utils'

export default function* contractsSaga() {
  yield* restartOnConfigChange(pollContracts)
}

function* pollContracts() {
  while (true) {
    const {account, netId} = yield select()
    try {
      yield call(getContracts, account, netId)
    } catch (err) {
      setTimeout(() => {
        console.error(`An error occurred while fetching contracts:`)
        throw err
      }, 0)
    }
    yield delay(5000)
  }
}

function* getContracts(account, netId) {
  if (!account || getConnectionError(account, netId)) {
    return
  }
  const contracts = yield call(fetchOwnerContracts, account)
  yield put(Actions.setContracts(contracts))
}
