import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import SmallSpinner from './small-spinner'

const heroButtonTypography = css`
  font-family: 'Rubik', Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
`

const BasicButtonTag = styled.button`
  ${heroButtonTypography}

  box-sizing: border-box;
  padding: 12px 24px;

  width: ${props => props.width ? props.width : 'auto'};
  height: ${props => props.height ? props.height : 'auto'};

  display: inline-flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  outline: none;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
`

const heroPrimaryColor = props => props.disabled ? '#E7EDF4' : '#3A6BF3'
const heroTextColor = props => props.disabled ? '#AEBBC8' : 'white'

const HeroButtonTag = styled(BasicButtonTag)`
  border-radius: 6px;

  border: 1px solid ${props => heroPrimaryColor(props)};
  background-color: ${props => heroPrimaryColor(props)};
  color: ${props => heroTextColor(props)};

  &:hover, &:active {
    border: 1px solid ${props => props.disabled ? '#E7EDF4' : '#2F58D4'};
    background-color: ${props => props.disabled ? '#E7EDF4' : '#2F58D4'};
    color: ${props => heroTextColor(props)};
  }
`

const regularPrimaryColor = props => props.disabled ? '#E7EDF4' : '#3A6BF3'
const regularTextColor = props => props.disabled ? '#AEBBC8' : '#3A6BF3'

const RegularButtonTag = styled(BasicButtonTag)`
  border-radius: 6px;
  font-size: 12px;

  border: 1px solid ${props => regularPrimaryColor(props)};
  background-color: white;
  color: ${props => regularTextColor(props)};

  &:hover, &:active {
    border: 1px solid ${props => regularPrimaryColor(props)};
    background-color: ${props => props.disabled ? 'white' : '#3A6BF3'};
    color: ${props => props.disabled ? '#AEBBC8' : 'white'};
  }
`

const ConnectButtonTag = styled(RegularButtonTag)`
  border-radius: 24px;
`

const WrongNetworkButtonTag = styled(RegularButtonTag)`
  border-radius: 24px;

  border: 1px solid #FD667E;
  background-color: #FD667E;
  color: white;

  &:hover, &:active {
    border: 1px solid #FC3254;
    background-color: #FC3254;
  }
`

const GenerateKeyButtonTag = styled(HeroButtonTag)`
  color: white;
  background-color: #00c48c;
  border: 1px solid #00c48c;

  &:hover, &:active {
    color: white;
    background-color: #00B581;
    border: 1px solid #00B581;
  }
`

const SpinnerWrapper = styled.span`
  width: 20px;
  height: 16px;
  margin-left: 4px;
  position: relative;
  top: -8.5px;
  left: -2px;
`

export class Button extends Component {
  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    mode: PropTypes.oneOf([
      'hero',
      'regular',
      'connect',
      'wrong-network',
      'generate-key',
    ]),
  }

  render() {
    const {
      children,
      disabled,
      loading,
      onClick,
      width,
      height,
      mode = 'regular'
    } = this.props


    let ButtonTag
    if (mode == 'hero') {
      ButtonTag = HeroButtonTag
    } else if (mode == 'connect') {
      ButtonTag = ConnectButtonTag
    } else if (mode == 'wrong-network') {
      ButtonTag = WrongNetworkButtonTag
    } else if (mode == 'generate-key') {
      ButtonTag = GenerateKeyButtonTag
    } else {
      ButtonTag = RegularButtonTag
    }

    let spinnerColor
    if (mode == 'hero') {
      if (disabled) {
        spinnerColor = '#8D9CAC'
      } else {
        spinnerColor = '#fff'
      }
    } else {
      if (disabled) {
        spinnerColor = '#8D9CAC'
      } else {
        spinnerColor = '#88BDF0'
      }

    }

    return (
      <ButtonTag
        onClick={onClick}
        disabled={disabled}
        width={width}
        height={height}
      >
        <span>
          {children}
        </span>
        {loading && <SpinnerWrapper>
          <SmallSpinner color={spinnerColor} scale={0.5} strokeWidth='3px'/>
        </SpinnerWrapper>}
      </ButtonTag>
    )
  }
}

export default Button
