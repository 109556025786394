import { css } from 'styled-components'

const link = css`
  font-family: 'Rubik', Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
`

const h1 = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 38px;
  line-height: 42px
`

const h2 = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px
`

const h3 = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px
`

const button = css`
  font-family: 'Rubik', Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
`

const input = css`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.45px;
`

const inputLabel = css`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.3px;
`

const label1 = css`
  font-family: 'Rubik', Roboto, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
`

const label2 = css`
  font-family: 'Rubik', Roboto, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
`

const body1 = css`
  font-family: 'Rubik', Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.2px;
`

const body2 = css`
  font-family: 'Rubik', Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
`

const smallText = css`
  font-family: 'Rubik', Roboto, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
`

export default {
  h1,
  h2,
  h3,
  button,
  input,
  inputLabel,
  label1,
  label2,
  body1,
  body2,
  smallText,
  link,
}
