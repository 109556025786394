import BigNumber from 'bignumber.js'
import {ErrorCodes, isNetworkWithIdSupported} from 'bitcannery-core'
import Juration from './juration'

export const ConnectionErrorCodes = {
  NO_ETHEREUM_CLIENT: ErrorCodes.NO_ETHEREUM_CLIENT,
  WRONG_NETWORK: 'WRONG_NETWORK',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
}

export function getConnectionError(account, netId) {
  const isNetworkSupported = isNetworkWithIdSupported(netId)
  if (account && isNetworkSupported) {
    return undefined
  }
  if (!isNetworkSupported) {
    return ConnectionErrorCodes.WRONG_NETWORK
  }
}

export function validatePrivateKey(key) {
  if(key[0] != '0' || key[1] != 'x') {
    return 'Should start with 0x'
  }

  if (!(/^0x[a-f0-9]/.test(key))) {
    return 'Should contain digits or a,b,c,d,e,f only'
  }

  if (!(/^0x[a-f0-9]{64}$/.test(key))) {
    return `Key should have 64 hex digits (32 bytes)`
  }

  return null
}

export function validatePublicKey(key) {
  if(key[0] != '0' || key[1] != 'x') {
    return 'Should start with 0x'
  }

  if (!(/^0x[a-f0-9]/.test(key))) {
    return 'Should contain digits or a,b,c,d,e,f only'
  }

  if (!(/^0x[a-f0-9]{130}$/.test(key))) {
    return `Key should have 130 hex digits (65 bytes)`
  }

  if (key[2] != '0' || key[3] != '4') {
    return `Key's first byte should be '04'`
  }

  return null
}

export function validateCheckinInterval(interval) {
  try {
    Juration.parse(interval)
  } catch (e) {
    return e.slice(e.indexOf('Unable to parse: '))
  }

  return null
}

export function formatWei(weiAmount) {
  weiAmount = new BigNumber(weiAmount)
  const weiAmountAbs = weiAmount.abs()
  if (weiAmountAbs.isGreaterThanOrEqualTo('1e12')) {
    return round(weiAmount.shiftedBy(-18), 7) + ' ETH'
  } else if (weiAmountAbs.isGreaterThanOrEqualTo('1e8')) {
    return round(weiAmount.shiftedBy(-9), 7) + ' Gwei'
  } else {
    return round(weiAmount, 7) + ' wei'
  }
}

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

export const SECRET_DRAFT_ID = 'SECRET_DRAFT'
