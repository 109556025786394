import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import SmallPane from './small-pane'
import SmallSpinner from './small-spinner'
import NetworkIcon from './img/network-icon'
import CheckIcon from './img/check-icon'

const CheckWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transform: scale(1.2);
  padding-top: 1px;
`


export default function KeeperJoiningStatus({keepersJoined, numKeepers}) {
  let firstRow, secondRow, spinnerOrCheck
  if (keepersJoined < numKeepers) {
    firstRow = 'Waiting for Keepers…'
    secondRow = `${keepersJoined} of ${numKeepers} Keepers Joined`
    spinnerOrCheck = <SmallSpinner />
  } else {
    firstRow = 'Proposals Received'
    secondRow = `${keepersJoined} Keepers Joined`
    spinnerOrCheck = <CheckWrapper><CheckIcon /></CheckWrapper>
  }

  return <SmallPane
    firstRowColor='#3D6FEF'
    firstRow={firstRow}
    secondRow={secondRow}
    textSectionWidth='184px'
    left={<NetworkIcon />}
    right={spinnerOrCheck}
  />
}

KeeperJoiningStatus.propTypes = {
  keepersJoined: PropTypes.number,
  numKeepers: PropTypes.number
}
