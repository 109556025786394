import BigNumber from 'bignumber.js'
import React, {useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {Actions} from '../redux'
import Juration from '../juration'

import styles from './styles'
import Input from './input'
import Button from './button'
import Stepper from './stepper'
import SmallPane from './small-pane'
import KeeperJoiningStatus from './keeper-joining-status'
import Spinner from './spinner'

import StateNames from './utils/state-names'
import {SecretState} from '../constants'
import useScrollToTop from './utils/use-scroll-to-top'
import calculateMonthlyKeepingFee from './utils/calculate-monthly-keeping-fee'

import {formatWei, validatePublicKey} from '../utils'
import {sel} from '../redux'


const H1 = styled.h1`
  ${styles.typography.h1}
  color: ${styles.colors.mainText}
  margin-top: 16px;
  margin-bottom: 16px;
`

const H2 = styled.h2`
  ${styles.typography.h2}
  color: ${styles.colors.mainText}
  margin-top: 16px;
  margin-bottom: 16px;
`

const Message = styled(H2)`
  text-align: center;
`

const P = styled.h1`
  ${styles.typography.body1}
  color: ${styles.colors.mainText}
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '24px'};
`

const KeeperControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 48px;
`

const ContractParamsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`

const LabeledControlWrapper = styled.div`
  margin-right: 24px;
`

const Label = styled.div`
  ${styles.typography.label1};
  color: ${styles.colors.mainText};
  margin-bottom: 8px;
  text-transform: uppercase;
`

const LabeledControl = ({title, children}) => (
  <LabeledControlWrapper>
    <Label>{title}</Label>
    {children}
  </LabeledControlWrapper>
)

export default function({match}) {
  useScrollToTop()

  const descInput = useRef(null)
  const secretInput = useRef(null)
  const pubkeyInput = useRef(null)
  const checkinIntervalInput = useRef(null)
  const keepersNumberInput = useRef(null)
  const dispatch = useDispatch()

  const {secretId} = match.params
  const secret = useSelector(state => sel.secretWithId(state, secretId))

  console.log('secret', secret)

  if (!secret) {
    return <Message>Secret "{secretId}" not found</Message>
  }

  if (secret.state === SecretState.Deploying) {
    return <Spinner className='spinner' />
  }

  if (secret.state !== SecretState.CallForKeepers && secret.state !== SecretState.Activating) {
    return <Message>Secret has been already activated</Message>
  }

  const isActivating = secret.state === SecretState.Activating
  const averageHistoricMonthlyFee = new BigNumber('57000000000000000')
  const keepingFee = calculateMonthlyKeepingFee(secret, averageHistoricMonthlyFee)

  return <div className='form-wrapper'>
    <H1>Deploy New Secret</H1>
    <P>
      You've just published the Keeper Request to Ethereum blockchain.
      Now you need to wait until the keepers join, and then finish the deployment process.
    </P>
    <ContractParamsWrapper>
      <SmallPane
        firstRow='Autogenerated Secret Name'
        secondRow={secretId}
      />
      <div style={{width: '8px', height: '10px'}}> </div>
      <SmallPane
        firstRow='Secret Address'
        secondRow={secret.address}
      />
      <div style={{width: '8px', height: '10px'}}> </div>
      <SmallPane
        firstRow='Status'
        secondRow={StateNames[secret.state]}
      />
    </ContractParamsWrapper>

    <H2>Secret</H2>
    <P marginBottom='16px'>
      Secret is a message you're sharing. It'll be encrypted twice: first time with a public key
      of the recipient, and the second time with a custom AES key to prevent them from getting
      the message too early.
    </P>
    <Input
      ref={descInput}
      disabled={isActivating}
      defaultValue={secret.localData.description}
      width='100%'
      onBlur={() => {
        if (descInput.current.isValid()) {
          const newDescription = descInput.current.value()
          dispatch(Actions.updateContractData(secret.id, {description: newDescription}))
        }
      }}
      placeholder='Description to remember what it is about (stored locally)'
    />
    <Input
      multiline
      required
      disabled={isActivating}
      defaultValue={secret.localData.secret}
      onBlur={() => {
        if (secretInput.current.value() === '' || secretInput.current.isValid()) {
          const newSecret = secretInput.current.value()
          dispatch(Actions.updateContractData(secret.id, {secret: newSecret}))
        }
      }}
      ref={secretInput}
      width='100%'
      marginBottom='40px'
      placeholder='Secret Text'
    />
    <H2>Recipient</H2>
    <P marginBottom='16px'>
      The person you're sharing the secret with needs to have an ECC keypair. You could ask them
      to get one here (in the "Generate key" section) and send you the public key. We'll encrypt
      the secret with it.
    </P>
    <Input
      required
      disabled={isActivating}
      ref={pubkeyInput}
      width='100%'
      defaultValue={secret.localData.recipientPublicKey}
      onBlur={() => {
        if (pubkeyInput.current.value() === '' || pubkeyInput.current.isValid()) {
          const newPubkey = pubkeyInput.current.value()
          dispatch(Actions.updateContractData(secret.id, {recipientPublicKey: newPubkey}))
        }
      }}
      validate={validatePublicKey}
      marginBottom='40px'
      placeholder='Recipient’s ECC public key'
    />
    <H2>Check-In Interval</H2>
    <P marginBottom='16px'>
      Check-in interval defines how often you'll need to check in with the system to postpone
      revealing the secret to the recipient. When you miss a check-in, the second layer of
      encryption is deciphered and the recipient gets the secret.
    </P>
    <Input
      disabled={true}
      defaultValue={secret.checkInInterval ? Juration.humanize(secret.checkInInterval) : ''}
      ref={checkinIntervalInput}
      width='100%'
      marginBottom='40px'
      placeholder={`Check-in interval, can't been changed on this stage`}
    />
    <H2>Keepers</H2>
    <P marginBottom='16px'>
      The secret is hidden from the recipient until you miss a check-in. The secret is encrypted
      with an AES key which gets shared between keepers. A keeper is an agent who earns the fee
      for securely storing their part of your AES key, and for disclosing it after you miss a
      check-in. Keepers won't be able to read your secret since each keeper only has a partial AES
      key. Moreover, only the recipient has the ECC key needed to unseal the second layer of
      encryption. After check-in is missed, the recipient will still be able to get the secret even
      if one third of your secret's keepers will have become inactive, since we use Shamir's secret
      sharing algorithm to distribute the key among keepers.
    </P>

    <KeeperControlsWrapper>
      <LabeledControl title='Keeper Status'>
        <KeeperJoiningStatus keepersJoined={secret.proposals.length} numKeepers={secret.localData.numKeepers}/>
      </LabeledControl>
      <LabeledControl title='Keepers'>
        <Stepper
          min={2}
          max={12}
          disabled={isActivating}
          onChange={(value) => {
            dispatch(Actions.updateContractData(secret.id, {numKeepers: value}))
          }}
          ref={keepersNumberInput}
          value={secret.localData.numKeepers}
        />
      </LabeledControl>
      <LabeledControl title='Redundancy'>
        <SmallPane
          textSectionWidth='164px'
          firstRow={`Minimum ${Math.max(Math.floor(secret.localData.numKeepers * 2 / 3), 2)} Keepers`}
          secondRow='Required to Decrypt'
        />
      </LabeledControl>
      <LabeledControl title={keepingFee.isEstimation ? 'Total Fee Estimate' : 'Total Fee'}>
        <SmallPane
          firstRow={formatWei(keepingFee.total)}
          secondRow='Total Per Month'
        />
      </LabeledControl>
    </KeeperControlsWrapper>

    <Button
      width='100%'
      height='48px'
      mode='hero'
      loading={isActivating}
      disabled={isActivating || secret.proposals.length < secret.localData.numKeepers}
      onClick={() => {
        if (secret.proposals.length < secret.localData.numKeepers) {
          return
        }
        if (secret.state !== SecretState.CallForKeepers) {
          return
        }
        dispatch(Actions.activateContract(secret.id))
      }}
    >
      {isActivating ? 'Activating Secret' : 'Activate Secret'}
    </Button>
  </div>
}
