import {spawn, takeEvery} from 'redux-saga/effects'

import * as Actions from '../actions'

export function* restartOnConfigChange(fn) {
  let task = null

  function* handleConfigChange() {
    if (task && task.isRunning()) {
      task.cancel()
    }
    task = yield spawn(fn)
  }

  yield takeEvery(Actions.setConfig.type, handleConfigChange)
}
