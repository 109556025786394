import {channel, buffers} from 'redux-saga'
import {call, put, takeEvery} from 'redux-saga/effects'

import {
  getBrowserWeb3,
  requestBrowserWeb3,
  init,
} from 'bitcannery-core'

import * as Actions from '../actions'
import {getConnectionError, ConnectionErrorCodes} from '../../utils'

export default function* connectSaga() {
  const configChan = channel(buffers.sliding(1))
  const onConfigChange = config => configChan.put(config)
  yield takeEvery(Actions.connect.type, access, requestBrowserWeb3, onConfigChange)
  yield call(access, getBrowserWeb3, onConfigChange)
  yield takeEvery(configChan, handleConfigChange)
}

function* access(accessStrategy, onEthConfigChange) {
  let conn
  try {
    conn = yield call(accessStrategy, onEthConfigChange)
  } catch (err) {
    yield put(Actions.setConfig(null, null, ConnectionErrorCodes.NO_ETHEREUM_CLIENT))
    return
  }
  const errorCode = getConnectionError(conn.account, conn.netId)
  if (errorCode == null) {
    yield call(init, conn.web3)
  }
  yield put(Actions.setConfig(conn.account, conn.netId, errorCode))
}

function* handleConfigChange({netId, account}) {
  yield put(Actions.setConfig(account, netId, getConnectionError(account, netId)))
}
