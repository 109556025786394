import BigNumber from 'bignumber.js'

const SECONDS_IN_MONTH = 60 * 60 * 24 * 30

export default function calculateMonthlyKeepingFee(secret, averageHistoricMonthlyFee) {
  const {numKeepers} = secret.localData

  const monthlyMult = SECONDS_IN_MONTH / (
    secret.checkInInterval || secret.localData.checkInInterval)

  const feesSorted = (secret.proposals || [])
    .map(p => p.keepingFee.times(monthlyMult).integerValue(BigNumber.ROUND_UP))
    .sort((a, b) => a.minus(b))

  const totalFee = feesSorted
    .slice(0, numKeepers)
    .reduce((total, fee) => total.plus(fee), new BigNumber(0))

  if (feesSorted.length >= numKeepers) {
    return {
      total: totalFee,
      isEstimation: false,
    }
  }

  const nextKeeperFee = feesSorted.length === 0
    ? averageHistoricMonthlyFee
    : feesSorted[feesSorted.length - 1]

  return {
    total: totalFee.plus(nextKeeperFee.times(numKeepers - feesSorted.length)),
    isEstimation: true,
  }
}
