import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import styles from './styles'
import UpDownIcon from './img/updown-icon'

const StyledInputWrapper = styled.div`
  width: 88px;
  height: 56px;
  position: relative;
  box-sizing: border-box;
`

const IconWrapper = styled.span`
  position: relative;
  left: -.5px;
  top: -1px;
`

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  ${styles.typography.input}
  border-radius: 3px;
  color: ${props => props.disabled ? styles.colors.secondaryText : styles.colors.mainText};
  padding-right: 32px;
  text-align: center;
  outline: none;

  border: 1px solid ${props => {
    return props.focused ? styles.colors.mainPrimary : styles.colors.blueMoon
  }};

  &:focus {
    border-color: ${styles.colors.mainPrimary};
  }

  &:disabled {
    background-color: ${styles.colors.white};
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const StyledControls = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 32px;
`

const StyledButton = styled.button`
  width: 100%;
  height: 28px;
  background: ${styles.colors.alabaster};
  border: solid 1px ${styles.colors.blueMoon};
  outline: none;
  cursor: pointer;
  transition: background .3s;

  &:hover {
    background-color: ${props => !props.disabled ? styles.colors.blueMoon:styles.colors.alabaster};
  }

  &:first-child {
    border-top-right-radius: 3px;
    box-shadow: 1px 0 0 ${styles.colors.blueMoon} inset;
    border-left-style: none;

    border-right-color: ${props => {
      return props.focused ? styles.colors.mainPrimary : styles.colors.blueMoon
    }};

    border-top-color: ${props => {
      return props.focused ? styles.colors.mainPrimary : styles.colors.blueMoon
    }};

    ${props => props.disabled
      ? css`
          svg > g > g {
            stroke: ${styles.colors.solitude};
          }
        `
      : null}
  }

  &:last-child {
    border-bottom: none;
    border-top-left-radius: 3px;
    transform: rotate(180deg);

    box-shadow: -1px 0 0 ${styles.colors.blueMoon} inset;
    border-right-style: none;

    border-top-color: ${props => {
      return props.focused ? styles.colors.mainPrimary : styles.colors.blueMoon
    }};

    border-left-color: ${props => {
      return props.focused ? styles.colors.mainPrimary : styles.colors.blueMoon
    }};

    ${props => props.disabled
      ? css`
          svg > g > g {
            stroke: ${styles.colors.solitude};
          }
        `
      : null}
  }
`

export class Stepper extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    focused: PropTypes.bool,
    value: PropTypes.number,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
    min: 2,
    max: 30,
    step: 1,
    focused: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.value
    }
  }

  increaseValue() {
    const {value} = this.state
    if (value === null) {
      this.setState({
        value: 2
      })
    } else if (value < this.props.max) {
      this.setState({
        value: value + 1
      })
      this.props.onChange && this.props.onChange(value + 1)
    }
  }

  decreaseValue() {
    const {value} = this.state
    if (value === null) {
      this.setState({
        value: 2
      })
    } else if (value > this.props.min) {
      this.setState({
        value: value - 1
      })
      this.props.onChange && this.props.onChange(value - 1)
    }
  }

  handleChange(e) {
    this.setState({
      value: Number(e.target.value)
    })
    this.props.onChange && this.props.onChange(e.target.value)
  }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.setState({ focused: false })
  }

  value = () => {
    return this.state.value
  }

  render() {
    const {disabled, placeholder, min, max, step} = this.props
    const {focused, value} = this.state
    return (
      <StyledInputWrapper>
        <StyledInput
          type='number'
          step={step}
          disabled={disabled}
          min={min}
          max={max}
          focused={focused}
          value={value ? value : undefined}
          onChange={e => this.handleChange(e)}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        <StyledControls>
          <StyledButton
            onClick={() => this.increaseValue()}
            focused={focused}
            disabled={value == max || disabled}
          >
            <UpDownIcon/>
          </StyledButton>
          <StyledButton
            onClick={() => this.decreaseValue()}
            focused={focused}
            disabled={value == min || disabled}
          >
            <IconWrapper>
              <UpDownIcon/>
            </IconWrapper>
          </StyledButton>
        </StyledControls>
      </StyledInputWrapper>
    )
  }
}

export default Stepper
