import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import styled, {css} from 'styled-components'

import Button from './button'
import EthereumAddress from './ethereum-address'
import NetNames from './utils/network-names'

import * as Actions from '../redux/actions'
import {sel} from '../redux'
import {ConnectionErrorCodes} from '../utils'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NetworkName = styled.div`
  font-family: 'Rubik', Roboto, sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 42px;
  padding-right: 10px;
  color: #8D9CAD;
  flex-shrink: 0;
`

export default function() {
  const {account, netId, connectionError} = useSelector(sel.connectionState)
  const dispatch = useDispatch()
  const connect = () => dispatch(Actions.connect())

  if (!netId && !connectionError) {
    return false
  }

  if (connectionError === ConnectionErrorCodes.NO_ETHEREUM_CLIENT) {
    return <Wrapper><Button mode='wrong-network'>No client</Button></Wrapper>
  }

  const netName = NetNames[netId]

  if (connectionError === ConnectionErrorCodes.WRONG_NETWORK) {
    return withNetName(
      netName,
      <Button mode='wrong-network'>Wrong network</Button>,
    )
  }

  if (!account) {
    return withNetName(
      netName,
      <Button mode='connect' onClick={connect}>Connect</Button>,
    )
  }

  return withNetName(
    netName,
    <EthereumAddress address={account} />,
  )
}

function withNetName(netName, btn) {
  return <Wrapper>
    <NetworkName>{netName || 'No'} network</NetworkName>
    {btn}
  </Wrapper>
}
