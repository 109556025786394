import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Router} from 'react-router'

import history from './history'
import App from './components/app'
import {store} from './redux'

const completeApp = <Provider store={store}><Router history={history}><App /></Router></Provider>

ReactDOM.render(completeApp, document.getElementById('root'))
