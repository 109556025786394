import * as Actions from './actions'

export function isInitializing(state = true, action) {
  if (action.type === Actions.setContracts.type) {
    return false
  }
  if (
    action.type === Actions.setConfig.type && (
    action.account == undefined || action.connectionError != null)
  ) {
    return false
  }
  return state
}

export function account(state = null, {type, account}) {
  if (type === Actions.setConfig.type) {
    return account || ''
  }
  return state
}

export function netId(state = null, {type, netId}) {
  if (type === Actions.setConfig.type) {
    return netId
  }
  return state
}

export function connectionError(state = null, {type, connectionError}) {
  if (type === Actions.setConfig.type) {
    return connectionError || ''
  }
  return state
}

export function contracts(state = null, {type, contracts}) {
  if (type === Actions.setConfig.type) {
    return null
  }
  if (type === Actions.setContracts.type) {
    return contracts
  }
  return state
}

export function ephemeralDataByContractId(state = {}, action) {
  switch (action.type) {
    case Actions.checkIn.type: {
      return updateContractData(state, action.contractId, {checkingIn: true})
    }
    case Actions.checkInFinished.type: {
      return updateContractData(state, action.contractId, {checkingIn: false})
    }
    default: {
      return state
    }
  }
}

export function localDataByContractId(state = {}, action) {
  switch (action.type) {
    case Actions.localDataFetched.type: {
      return action.localData
    }
    case Actions.deployContract.type: {
      const data = {...action.contract, createdAt: action.timestamp}
      return {...state, [action.contract.id]: data}
    }
    case Actions.updateContractData.type: {
      return updateContractData(state, action.contractId, action.newData)
    }
    case Actions.activateContract.type: {
      return updateContractData(state, action.contractId, {activated: true})
    }
    case Actions.activateContractFailed.type: {
      return updateContractData(state, action.contractId, {activated: false})
    }
    default: {
      return state
    }
  }
}

function updateContractData(dataByContractId, contractId, newFields) {
  const contractData = dataByContractId[contractId] || {}
  const newContractData = {...contractData}

  Object.keys(newFields).forEach(key => {
    const value = newFields[key]
    if (value !== undefined) {
      newContractData[key] = value
    }
  })

  return {...dataByContractId, [contractId]: newContractData}
}

const DEFAULT_CURRENT_SECRET_STATE = { loading: false, secretText: null, error: null }

export function currentSecret(state = DEFAULT_CURRENT_SECRET_STATE, {type, ...action}) {
  if (type === Actions.readSecret.type) {
    return { loading: true, secretText: null, error: null }
  }
  if (type === Actions.readSecretSuccess.type) {
    return { loading: false, secretText: action.secretText, error: null }
  }
  if (type === Actions.readSecretError.type) {
    return { loading: false, secretText: null, error: action.error }
  }
  if (type === Actions.cleanCurrentSecret.type) {
    return DEFAULT_CURRENT_SECRET_STATE
  }
  return state
}
