import Web3 from 'web3'
import truffleContract from 'truffle-contract'

import {BitCanneryContractABI, RegistryContractABI} from './contract-abis'

import * as ContractAPI from './contract-api'
import * as encryption from './utils/encryption'
import * as packingUtils from './utils/pack'

export {encryption}

export const {ContractState} = ContractAPI

export const ErrorCodes = {
  NO_ETHEREUM_CLIENT: 'NO_ETHEREUM_CLIENT',
}

export const NetworkIds = {
  Mainnet: 1,
  Morden: 2,
  Ropsten: 3,
  Rinkeby: 4,
  Kovan: 42,
}

const registryAdressByNetworkId = {
  [NetworkIds.Mainnet]: '0x0481D19CDd6a12aa2f85b4a46A31D1d17BA33543',
  [NetworkIds.Rinkeby]: '0x5A6Db32a129e9F3ADE0c2c9D7Ed382b8607Ae6F3',
}

export const supportedNetworkIds = [NetworkIds.Mainnet, NetworkIds.Rinkeby]

export function isNetworkWithIdSupported(netId) {
  return supportedNetworkIds.indexOf(+netId) !== -1
}

let web3
let registry
let netId

const connection = {}

export async function init(web3_) {
  web3 = connection.web3 = web3_

  if (!connection.RegistryContract) {
    connection.RegistryContract = truffleContract(RegistryContractABI)
    connection.BitCanneryContract = truffleContract(BitCanneryContractABI)

    connection.RegistryContract.numberFormat = 'String'
    connection.BitCanneryContract.numberFormat = 'String'
  }

  connection.RegistryContract.setProvider(web3.currentProvider)
  connection.BitCanneryContract.setProvider(web3.currentProvider)

  const netId = await web3.eth.net.getId()
  if (netId !== connection.netId) {
    connection.netId = netId

    const registryAddr = registryAdressByNetworkId[netId]
    if (!registryAddr) {
      throw new Error(`unsupported network`)
    }

    console.log(`waiting for registry, net id: ${netId}`)

    registry = connection.registry = await connection.RegistryContract
      .at(registryAddr)
      .then(x => x)

    console.log(`got registry`)
  }
}

export async function getBrowserWeb3(onConfigChanged) {
  return await obtainBrowserWeb3(onConfigChanged, false)
}

export async function requestBrowserWeb3(onConfigChanged) {
  return await obtainBrowserWeb3(onConfigChanged, true)
}

async function obtainBrowserWeb3(onConfigChanged, requestAccessIfNeeded = true) {
  if (window.ethereum) {
    window.ethereum.autoRefreshOnNetworkChange = true // TODO: turn off

    const web3 = new Web3(window.ethereum)
    const config = await getCurrentConfig(web3)

    let netId = config.netId
    let account

    if (config.account != undefined || requestAccessIfNeeded) {
      try {
        const accounts = await window.ethereum.enable()
        account = accounts[0]
        console.log(`Ethereum connected`)
      } catch (error) {
        return {web3, netId, account}
      }
    }

    window.ethereum.on('accountsChanged', newAccounts => {
      account = newAccounts[0]
      onConfigChanged({netId, account})
    })

    window.ethereum.on('networkChanged', newNetId => {
      netId = newNetId
      onConfigChanged({netId, account})
    })

    return {web3, netId, account}
  }

  if (window.web3) {
    const web3 = new Web3(window.web3.currentProvider)
    let config = await getCurrentConfig(web3)

    console.log(`Legacy Ethereum connected`)

    const checkConfigUpdates = async () => {
      const newConfig = await getCurrentConfig(web3)
      if (newConfig.account !== config.account || newConfig.netId !== config.netId) {
        config = newConfig
        onConfigChanged({...config})
      }
    }

    setInterval(checkConfigUpdates, 1000)

    return {...config, web3}
  }

  const err = new Error('No ethereum client installed in the browser')
  err.code = ErrorCodes.NO_ETHEREUM_CLIENT
  throw err
}

async function getCurrentConfig(web3) {
  const [accounts, netId] = await Promise.all([web3.eth.getAccounts(), web3.eth.net.getId()])
  return {account: accounts[0], netId}
}

export async function fetchOwnerContracts(ownerAddress) {
  return await ContractAPI.fetchOwnerContracts(connection, ownerAddress)
}

export async function deployContract(id, checkInInterval) {
  const {account} = await getCurrentConfig(web3)
  const result = await connection.registry.deployAndRegisterContract(
    id,
    checkInInterval,
    {from: account},
  )
  return result.receipt.logs[0].args.addr
}

export function getNumKeepersRequiredForRecovery(numKeepers) {
  return Math.max(Math.floor(numKeepers * 2 / 3), 2)
}

export async function activateContract(
  id,
  secretText,
  numKeepers,
  numKeepersToRecover,
  bobPublicKey
) {
  const [instance, {account}] = await Promise.all([
    ContractAPI.getBitCanneryContractWithId(connection, id),
    getCurrentConfig(web3),
  ])

  const proposals = await ContractAPI.fetchKeeperProposals(instance)
  if (proposals.length < numKeepers) {
    throw new Error(`insufficient number of keeper proposals`)
  }

  const proposalIndices = pickCheapestProposals(proposals, numKeepers)
  const activationPrice = await instance.calculateActivationPrice(proposalIndices)
  const secretData = Buffer.from(secretText, 'utf8').toString('hex')
  const keeperPublicKeys = proposalIndices.map(i => proposals[i].publicKey)

  const encryptionResult = await encryption.encryptData(
    secretData,
    bobPublicKey,
    keeperPublicKeys,
    numKeepersToRecover,
  )

  const packedEncryptedKeyParts = packingUtils.pack(encryptionResult.encryptedKeyParts)

  await instance.acceptKeepersAndActivate(
    encryptionResult.shareLength,
    encryptionResult.legacyDataHash,
    encryptionResult.aesCounter,
    proposalIndices,
    encryptionResult.keyPartHashes,
    packedEncryptedKeyParts,
    encryptionResult.encryptedLegacyData,
    {from: account, value: activationPrice},
  )
}

function pickCheapestProposals(keeperProposals, numKeepers) {
  return keeperProposals
    .map((v, i) => ({index: i, keepingFee: v.keepingFee}))
    .sort((a, b) => a.keepingFee.minus(b.keepingFee))
    .map(v => v.index)
    .slice(0, numKeepers)
}

export async function checkIn(contractId) {
  const [instance, {account}] = await Promise.all([
    ContractAPI.getBitCanneryContractWithId(connection, contractId),
    getCurrentConfig(web3),
  ])

  if (!await instance.canCheckIn()) {
    if (+(await instance.state()) >= ContractState.Active) {
      throw new Error(`check-in time missed`)
    } else {
      throw new Error(`contract is not active yet`)
    }
  }

  const checkInPrice = await instance.calculateApproximateCheckInPrice()
  const gasPrice = await instance.ownerCheckIn.estimateGas({from: account, value: checkInPrice})

  await instance.ownerCheckIn({
    from: account,
    value: checkInPrice,
    gas: Math.max(gasPrice, 100000),
  })
}

export async function checkContractExists(id) {
  const address = await connection.registry.getContractAddress(id)
  return !!address && address !== '0x0000000000000000000000000000000000000000'
}

export async function getDataToDecryptContract(id) {
  const {state, encryptedData, suppliedKeyPartsCount, keepersCount} = await ContractAPI.getContractSecretData(connection, id)
  const suppliedKeyParts = await ContractAPI.getSuppliedKeyParts(connection, id, suppliedKeyPartsCount)
  return {state, encryptedData, suppliedKeyPartsCount, keepersCount, suppliedKeyParts}
}

export async function decryptSecret(encryptedData, suppliedKeyParts, privateKey) {

  let legacy = null

  try {
    legacy = await encryption.decryptData(
      encryptedData.encryptedData,
      encryptedData.dataHash,
      privateKey,
      suppliedKeyParts,
      encryptedData.shareLength,
      encryptedData.aesCounter,
    )
  } catch (err) {}

  return legacy && Buffer.from(legacy.substring(2), 'hex').toString('utf8')
}
