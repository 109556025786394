import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import CheckIcon from './img/check-icon'
import styles from './styles'

const Container = styled.div`
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  height: ${props => props.multiline ? 'auto' : '46px'};
  width: ${props => props.width ? props.width : 'auto'};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '8px'};
`

const InputTag = styled.input`
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  ${styles.typography.input}

  color: ${props => {
    if (props.disabled) {
      return styles.colors.secondaryText
    } else {
      return styles.colors.mainText
    }
  }};

  border: 1px solid ${styles.colors.blueMoon};
  background-color: ${styles.colors.alabaster};
  border-radius: 3px;
  text-align: left;
  padding-left: 16px;
  padding-right: 36px;

  transition: all .2s ease-in-out;
  outline: 0;
  line-height: 20px;
  padding-top: ${props => {
    if (props.multiline) {
      return props.empty && props.valid ? '13px' : '20px'
    }
    return props.empty && props.valid ? '0px' : '16px'
  }};
  &::placeholder {
    ${styles.typography.input}
    line-height: ${props => {
      if (props.multiline) {
        return 'inherit'
      }
      return props.valid ? '46px': '28px'
    }};

    color: ${props => {
      if (props.disabled) {
        return styles.colors.palePrimary
      } else {
        return styles.colors.placeholderText
      }
    }};
  }
  ::selection {
    color: ${styles.colors.white};
    background: ${styles.colors.mainPrimary};
  }
  ::-moz-selection {
    color: ${styles.colors.white};
    background: ${styles.colors.mainPrimary};
  }
  border: 1px solid ${props => {
    if (props.focused) {
      return styles.colors.mainPrimary
    } else {
      return props.valid ? styles.colors.blueMoon : styles.colors.error
    }
  }};
  resize: none;
  min-height: ${props => props.multiline && '126px'};
  padding-bottom: ${props => props.multiline && '4px'};
`

const Label = styled.label`
  display: block;
  position: absolute;
  box-sizing: border-box;
  text-align: left;

  ${styles.typography.inputLabel}

  max-width: 100%;
  padding: 0;
  padding-right: 48px;
  background-color: ${styles.colors.alabaster};

  padding-left: 16.5px;
  padding-top: 7px;
  border-radius: 3px;
  top: 1px;
  left: 1px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;

  ${props => (props.empty && props.valid)
    ? css`
        opacity: 0;
        visibility: hidden;
      `
    : css`
        opacity: 1;
        visibility: visible;
      `
    }

  color: ${props => {
    if (props.focused) {
      return styles.colors.placeholderText
    } else if (props.disabled) {
      return styles.colors.secondaryText
    } else {
      return props.valid ? styles.colors.placeholderText : styles.colors.error
    }
  }};
`

const IconWrapper = styled.div`
  display: inline-block;
  position: absolute;
  right: 16px;
  top: 19px;

  transition: all .3s cubic-bezier(.2,.41,.55,1.69);

  transform: ${props => {
    if (props.empty) {
      return 'scale(0)'
    } else if (props.disabled) {
      return 'scale(0)'
    } else {
      return props.valid ? 'scale(1)' : 'scale(0)'
    }
  }};
`

export class Input extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    multiline: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    width: PropTypes.string,
    validate: PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
    required: false,
    multiline: false,
    validate: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      valid: true,
      empty: !props.defaultValue,
      errorMessage: '',
    }
  }

  handleInputRef = inputRef => {
    this.inputRef = inputRef
  }

  isValid () {
    return this.state.valid
  }

  validate = (callback) => {
    if (!callback) {
      callback = () => false
    }

    const value = this.inputRef.value
    const {validate, required} = this.props

    const validationError = validate && validate(value)
    const isValid = !validationError

    if (required && value === '') {
      this.setState({
        focused: false,
        valid: false,
        errorMessage: 'Required',
      }, callback)
      return false
    } else if (!isValid) {
      this.setState({
        focused: false,
        valid: false,
        errorMessage: validationError,
      }, callback)
      return false
    } else {
      this.setState({
        focused: false,
        valid: true,
        errorMessage: '',
      }, callback)
      return true
    }
  }

  onFocus = () => {
    this.setState({ focused: true })
    this.props.onFocus && this.props.onFocus()
  }

  value = () => {
    return this.inputRef.value
  }

  onBlur = () => {
    this.validate(this.props.onBlur)
  }

  handleChange = e => {
    const {onChange} = this.props
    this.setState({empty: !e.target.value})
    onChange && onChange(e.target.value)
  }

  focus = () => {
    this.inputRef.focus()
  }

  render() {
    const {
      placeholder,
      disabled,
      width,
      defaultValue,
      multiline,
    } = this.props

    const {
      empty,
      focused,
      valid,
      errorMessage,
    } = this.state

    return (
      <Container width={width} multiline={multiline}>
        <InputTag
          as={multiline && 'textarea'}
          multiline={multiline}
          type="text"
          spellCheck="false"
          defaultValue={defaultValue}
          focused={focused}
          valid={valid}
          empty={empty}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.handleChange}
          ref={this.handleInputRef}
        />
        <Label focused={focused} valid={valid} empty={empty} disabled={disabled}>
          {focused || valid
            ? placeholder
            : errorMessage}
        </Label>
        <IconWrapper valid={valid} empty={empty} disabled={disabled}>
          <CheckIcon/>
        </IconWrapper>
      </Container>
    )
  }
}

export default Input
