import React from 'react'
import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'
import styled, { css } from 'styled-components'

const linkTypography = css`
  font-family: 'Rubik', Roboto, sans-serif;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.1px;
`

const LinkTag = styled(NavLink)`
  ${linkTypography}
  cursor: pointer;

  &:hover, &.active {
    color: #3A6BF3;
  }
`

export default function Link({children, href, ...rest}) {
  return (
    <LinkTag activeClassName='active' isActive={isActive(href)} to={href} {...rest}>
      {children}
    </LinkTag>
  )
}

function isActive(href) {
  if (href !== '/') {
    return undefined
  }
  return (_, location) => {
    return location.pathname === '/'
    || location.pathname === '/new-secret'
    || location.pathname.indexOf('/secret/') === 0
  }
}

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string
}
