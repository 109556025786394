import BigNumber from 'bignumber.js'

export function addressIsZero(address) {
  // sometimes geth returns 0x instead of 0x0 which leads to exception
  // inside the BigNumber lib.
  if (address == '0x') {
    return true
  } else {
    return new BigNumber(address).isZero()
  }
}
