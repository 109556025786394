import {SecretState} from '../../constants'

export default {
  [SecretState.Deploying]: 'Deploying',
  [SecretState.CallForKeepers]: 'Waiting for keepers',
  [SecretState.Activating]: 'Activating',
  [SecretState.Active]: 'Active',
  [SecretState.CallForKeys]: 'Check-in missed',
  [SecretState.Cancelled]: 'Cancelled',
}
