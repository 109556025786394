import BigNumber from 'bignumber.js'
import React, {useRef, useCallback, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'

import {Actions, sel} from '../redux'
import {formatWei, validatePublicKey, validateCheckinInterval, SECRET_DRAFT_ID} from '../utils'
import Juration from '../juration'

import styles from './styles'
import Input from './input'
import Button from './button'
import Stepper from './stepper'
import SmallPane from './small-pane'
import Link from './link'

import useScrollToTop from './utils/use-scroll-to-top'

const H1 = styled.h1`
  ${styles.typography.h1}
  color: ${styles.colors.mainText}
  margin-top: 16px;
  margin-bottom: 16px;
`

const H2 = styled.h2`
  ${styles.typography.h2}
  color: ${styles.colors.mainText}
  margin-top: 16px;
  margin-bottom: 16px;
`

const P = styled.h1`
  ${styles.typography.body1}
  color: ${styles.colors.mainText}
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '24px'};
`

const KeeperControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 48px;
`

const LabeledControlWrapper = styled.div`
  margin-right: 40px;
`

const Label = styled.div`
  ${styles.typography.label1};
  color: ${styles.colors.mainText};
  margin-bottom: 8px;
  text-transform: uppercase;
`

const LabeledControl = ({title, children}) => (
  <LabeledControlWrapper>
    <Label>{title}</Label>
    {children}
  </LabeledControlWrapper>
)

export default function() {
  useScrollToTop()
  const secretLocalData = useSelector(sel.getDraftSecretLocalData)

  const averageKeeperPrice = new BigNumber('50000000000000000')
  const [estimatedTotal, setEstimatedTotal] = useState(averageKeeperPrice.times(secretLocalData.numKeepers || 2))
  const [isDeploying, setIsDeploying] = useState(false)

  const onKeeperNumChanged = useCallback((value) => {
    dispatch(Actions.updateContractData(SECRET_DRAFT_ID, {numKeepers: value}))
    setEstimatedTotal(averageKeeperPrice.times(value))
  })

  const descInput = useRef(null)
  const secretInput = useRef(null)
  const pubkeyInput = useRef(null)
  const checkinIntervalInput = useRef(null)
  const keepersNumberInput = useRef(null)

  const inputs = [
    descInput,
    secretInput,
    pubkeyInput,
    checkinIntervalInput
  ]

  const dispatch = useDispatch()
  const deploy = useCallback(() => {
    if (inputs.map(i => i.current.validate()).some(v => !v)) {
      return
    }

    setIsDeploying(true)

    dispatch(Actions.deployContract({
      description: descInput.current.value(),
      secret: secretInput.current.value(),
      recipientPublicKey: pubkeyInput.current.value(),
      checkInInterval: Juration.parse(checkinIntervalInput.current.value()),
      numKeepers: keepersNumberInput.current.value(),
    }))
  })

  return <div className='form-wrapper'>
    <H1>Deploy New Secret</H1>
    <P>
      You're about to set up a new secret. Secret sharing is powered by the Ethereum blockchain,
      so you'll need to send two Ethereum transactions in the process.
    </P>
    <H2>Secret</H2>
    <P marginBottom='16px'>
      Secret is a message you're sharing. It'll be encrypted twice: first time with a public key
      of the recipient, and the second time with a custom AES key to prevent them from getting
      the message too early.
    </P>
    <Input
      ref={descInput}
      disabled={isDeploying}
      width='100%'
      defaultValue={secretLocalData.description}
      onBlur={() => {
        if (descInput.current.isValid()) {
          const newDescription = descInput.current.value()
          dispatch(Actions.updateContractData(SECRET_DRAFT_ID, {description: newDescription}))
        }
      }}
      placeholder='Description to remember what it is about (stored locally)'
    />
    <Input
      multiline
      required
      disabled={isDeploying}
      defaultValue={secretLocalData.secret}
      ref={secretInput}
      onBlur={() => {
        if (secretInput.current.value() === '' || secretInput.current.isValid()) {
          const newSecret = secretInput.current.value()
          dispatch(Actions.updateContractData(SECRET_DRAFT_ID, {secret: newSecret}))
        }
      }}
      width='100%'
      marginBottom='40px'
      placeholder='Secret Text'
    />
    <H2>Recipient</H2>
    <P marginBottom='16px'>
      The person you're sharing the secret with needs to have an ECC keypair. You could ask them
      to get one in the <Link className='active' href='/generate-key' target='_blank'>Generate key</Link> section and send you the public key. We'll encrypt
      the secret with it.
    </P>
    <Input
      required
      disabled={isDeploying}
      ref={pubkeyInput}
      defaultValue={secretLocalData.recipientPublicKey}
      width='100%'
      onBlur={() => {
        if (pubkeyInput.current.value() === '' || pubkeyInput.current.isValid()) {
          const newPubkey = pubkeyInput.current.value()
          dispatch(Actions.updateContractData(SECRET_DRAFT_ID, {recipientPublicKey: newPubkey}))
        }
      }}
      validate={validatePublicKey}
      marginBottom='40px'
      placeholder='Recipient’s ECC public key'
    />
    <H2>Check-In Interval</H2>
    <P marginBottom='16px'>
      Check-in interval defines how often you'll need to check in with the system to postpone
      revealing the secret to the recipient. When you miss a check-in, the second layer of
      encryption is deciphered and the recipient gets the secret.
    </P>
    <Input
      required
      disabled={isDeploying}
      ref={checkinIntervalInput}
      width='100%'
      defaultValue={secretLocalData.checkInInterval ? Juration.humanize(secretLocalData.checkInInterval) : ''}
      validate={validateCheckinInterval}
      onBlur={() => {
        if (checkinIntervalInput.current.value() === '' || checkinIntervalInput.current.isValid()) {
          const newCheckinInterval = Juration.parse(checkinIntervalInput.current.value())
          dispatch(Actions.updateContractData(SECRET_DRAFT_ID, {checkInInterval: newCheckinInterval}))
        }
      }}
      marginBottom='40px'
      placeholder='Check-in interval, e.g. 1 min, 1 day or 1 month'
    />
    <H2>Keepers</H2>
    <P marginBottom='16px'>
      The secret is hidden from the recipient until you miss a check-in. The secret is encrypted
      with an AES key which gets shared between keepers. A keeper is an agent who earns the fee
      for securely storing their part of your AES key, and for disclosing it after you miss a
      check-in. Keepers won't be able to read your secret since each keeper only has a partial AES
      key. Moreover, only the recipient has the ECC key needed to unseal the second layer of
      encryption. After check-in is missed, the recipient will still be able to get the secret even
      if one third of your secret's keepers will have become inactive, since we use Shamir's secret
      sharing algorithm to distribute the key among keepers.
    </P>

    <KeeperControlsWrapper>
      <LabeledControl title='Average Keeper Price'>
        <SmallPane
          firstRow={`${formatWei(averageKeeperPrice)} (Based On Historical Data)`}
          secondRow='Per Keeper Per Month'
        />
      </LabeledControl>
      <LabeledControl title='Keepers'>
        <Stepper
          min={2}
          max={12}
          disabled={isDeploying}
          onChange={onKeeperNumChanged}
          ref={keepersNumberInput}
          value={secretLocalData.numKeepers || 2} />
      </LabeledControl>
      <LabeledControl title='Estimated Total'>
        <SmallPane
          firstRow={formatWei(estimatedTotal)}
          secondRow='Total Per Month'
        />
      </LabeledControl>
    </KeeperControlsWrapper>

    <Button
      width='100%'
      height='48px'
      mode='hero'
      onClick={deploy}
      loading={isDeploying}
      disabled={isDeploying}
    >
      {isDeploying ? 'Deploying Keeper Request' : 'Request Keepers'}
    </Button>
  </div>
}
