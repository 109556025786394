import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Jazzicon from 'jazzicon'

import styled, { css } from 'styled-components'

const linkTypography = css`
  font-family: 'Rubik', Roboto, sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.1px;
`

const Wrapper = styled.div`
  width: 152px;
  height: 40px;
  border: 1px solid #EAEAEA;
  border-radius: 20px;

  color: #8D9CAD;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #FAFBFC;
  }
`

const Address = styled.div`
  ${linkTypography}
  padding-left: 16px;
  color: #8D9CAD;
  padding-top: .5px;
  line-height: 42px;
`

const Icon = styled.div`
  height: 24px;
  width: 24px;
  margin-left: 8px;
  margin-right: 12px;
  border-radius: 20px;
  background: #EAEAEA;
`

export class EthereumAddress extends Component {
  static propTypes = {
    address: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  render() {
    const {
      address,
    } = this.props

    const addressLength = address.length
    const trancatedAddress = `0x${address.slice(2, 6)}...${address.slice(addressLength-6, addressLength)}`

    setTimeout(() => {
      const node = this.ref.current
      if (node) {
        while (node.firstChild) {
          node.removeChild(node.firstChild)
        }
        if (address) {
          node.appendChild(Jazzicon(24, parseInt(address.slice(2, 10), 16)))
        }
      }
    }, 0)

    return (
      <Wrapper>
        <Address>{trancatedAddress}</Address>
        <Icon ref={this.ref}/>
      </Wrapper>
    )
  }
}

export default EthereumAddress
