const mainPrimary = '#3D6FEF'
const mediumPrimary = '#88BDF0'
const palePrimary = '#D5DFFC'

const mainText = '#282C42'
const secondaryText = '#8D9CAC'
const placeholderText = '#697684'

const success = '#1EC38D'
const warning = '#FECE65'
const error = '#FA627C'

const solitude = '#E7EDF4'
const blueMoon = '#EBEFFC'
const zircon = '#F4F6FC'
const alabaster = '#FAFBFC'
const white = '#FFFFFF'

export default {
  mainPrimary,
  mediumPrimary,
  palePrimary,
  mainText,
  secondaryText,
  placeholderText,
  success,
  warning,
  error,
  solitude,
  blueMoon,
  zircon,
  alabaster,
  white,
}
