import React, {useState, useRef, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'

import {readSecret, cleanCurrentSecret} from '../redux/actions'
import {validatePrivateKey} from '../utils'
import {DecryptionErrorCodes} from '../constants'

import Hero from './hero'
import Lead from './lead'
import Input from './input'
import Button from './button'
import styles from './styles'

const MainLead = styled(Lead)`
  margin-bottom: 24px;
`

const ActionsRow = styled.div`
  display: flex;
`

const Spacer = styled.div`
  height: 24px;
`

const SecretContainer = styled.div`
  min-height: 72px;
  border-radius: 3px;
  border: solid 1px ${props => props.error ? styles.colors.error : '#e8eefe'};
  background-color: #fafbfc;
  margin-top: 8px;
  padding: 12px 80px 12px 16px;
  border-radius: 3px;
  width: 100%;
  overflow-wrap: break-word;
`

const Secret = styled.div`
  ${styles.typography.body2};
  color: ${props => props.error ? styles.colors.error : styles.colors.mainText};
`

const Label = styled.div`
  ${styles.typography.label1};
  color: ${props => props.error ? styles.colors.error : styles.colors.secondaryText};
  user-select: none;
  margin-bottom: 8px;
`

const ResultRow = styled.div``

export default function() {
  const [secretId, setSecretId] = useState('')
  const [privateKey, setPrivateKey] = useState('')
  const {loading, secretText, error} = useSelector(({currentSecret}) => currentSecret)
  const isInitializing = useSelector(state => state.isInitializing)
  const secretIdInput = useRef()
  const privateKeyInput = useRef()
  const dispatch = useDispatch()
  useEffect(_ => _ => dispatch(cleanCurrentSecret()), [])

  function getSecretText() {
    const secretIdIsValid = secretIdInput.current.validate()
    const privateKeyIsValid = privateKeyInput.current.validate()
    if (secretIdIsValid && privateKeyIsValid) {
      dispatch(readSecret(secretId, privateKey))
    }
  }

  return <>
    <Hero>Decrypt The Secret</Hero>
    <MainLead>
      To receive the secret shared with you, please, enter the secret's name and your ECC private key
    </MainLead>
    <Input width='100%' placeholder='Secret Name' onChange={setSecretId} ref={secretIdInput} required/>
    <Input width='100%' placeholder='ECC Private Key' onChange={setPrivateKey} ref={privateKeyInput} required validate={validatePrivateKey}/>
    <Spacer/>
    <ActionsRow>
      <Button width='100%' height='48px' mode='hero'
        disabled={isInitializing || loading} onClick={getSecretText} loading={loading}>
        Decrypt The Secret
      </Button>
    </ActionsRow>
    <Spacer/>
    <ResultRow>
      {secretText && (
        <SecretContainer>
          <Label>Secret {secretId}</Label>
          <Secret>{secretText}</Secret>
        </SecretContainer>
      )}
      {error && <SecretContainer error>
          <Label error>Error</Label>
          <Secret error>{decryptionErrorMessages[error] || error}</Secret>
        </SecretContainer>}
    </ResultRow>
  </>
}

const decryptionErrorMessages = {
  [DecryptionErrorCodes['CONTRACT_DOES_NOT_EXIST']]: `Secret doesn't exist`,
  [DecryptionErrorCodes['NOT_READY_FOR_DECRYPTION']]: `Secret can't be decrypted yet`,
  [DecryptionErrorCodes['NO_KEEPER_KEYS']]: `None of the keepers submitted decryption keys yet. Please wait a little and try again later.`,
  [DecryptionErrorCodes['INVALID_PRIVATE_KEY']]: `The private key you pasted here is not correct, please check it one more time`,
  [DecryptionErrorCodes['NOT_ENOUGH_KEY_PARTS']]: `Most likely, you need to wait until more keepers submit their keys. Also, check that the private key you pasted here is correct`,
  [DecryptionErrorCodes['LIKELY_INVALID_PRIVATE_KEY']]: `Most likely, the private key you pasted here is not correct, please check it one more time. Also, it might be that you need to wait until more keepers submit their keys`,
}
