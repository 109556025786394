import {call, put, takeEvery} from 'redux-saga/effects'
import {checkContractExists, getDataToDecryptContract, decryptSecret, ContractState} from 'bitcannery-core'

import {getNumKeepersRequiredForRecovery} from 'bitcannery-core'
import {DecryptionErrorCodes} from '../../constants'
import {readSecret, readSecretSuccess, readSecretError} from '../actions'

export default function* readSecretSaga() {
  yield takeEvery(readSecret.type, getSecretText)
}

function* getSecretText({secretId, privateKey}) {
  try {
    const contractExists = yield call(checkContractExists, secretId)
    if (!contractExists) {
      yield put(readSecretError(secretId, DecryptionErrorCodes['CONTRACT_DOES_NOT_EXIST']))
      return
    }

    const contractData = yield call(getDataToDecryptContract, secretId)
    const {state, suppliedKeyPartsCount, encryptedData, keepersCount, suppliedKeyParts} = contractData

    if (state !== ContractState.CallForKeys) {
      yield put(readSecretError(secretId, DecryptionErrorCodes['NOT_READY_FOR_DECRYPTION']))
      return
    }

    if (suppliedKeyPartsCount === 0) {
      yield put(readSecretError(secretId, DecryptionErrorCodes['NO_KEEPER_KEYS']))
      return
    }

    // //TODO: request private key here, not before the form starts fetching contract

    const decryptedSecret = yield call(decryptSecret, encryptedData, suppliedKeyParts, privateKey)

    if (decryptedSecret === null) {
      if (suppliedKeyPartsCount === keepersCount) {
        yield put(readSecretError(secretId, DecryptionErrorCodes['INVALID_PRIVATE_KEY']))
        return
      } else if (suppliedKeyPartsCount < getNumKeepersRequiredForRecovery(keepersCount)) {
        yield put(readSecretError(secretId, DecryptionErrorCodes['NOT_ENOUGH_KEY_PARTS']))
        return
      } else {
        yield put(readSecretError(secretId, DecryptionErrorCodes['LIKELY_INVALID_PRIVATE_KEY']))
        return
      }
    }
    yield put(readSecretSuccess(secretId, decryptedSecret))
  } catch (e) {
    yield put(readSecretError(secretId, e.message))
  }
}
