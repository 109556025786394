import React from 'react'
import NoSecrets from './img/no-secrets-picture'
import styled, {css} from 'styled-components'

const h3Typography = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.2px;
`

const H3 = styled.h3`
  ${h3Typography}
  color: #282B43;
  text-align: center;
  margin-bottom: 8px;
`

const Wrapper = styled.div`
  padding-top: 64px;
  width: 280px;
  margin: 0 auto;
`

const Desc = styled.div`
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  width: 320px;
  letter-spacing: 0.2px;
  margin: 0 auto;
  line-height: 20px;
`

const NoSecretsWidget = () => (
  <>
    <Wrapper>
      <NoSecrets />
    </Wrapper>
    <H3>No Secrets</H3>
    <Desc>You're about to share a new secret.<br/>Click "Deploy New Secret" to continue.</Desc>
  </>
)

export default NoSecretsWidget
