import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import styles from './styles'

const Pane = styled.div`
  box-sizing: border-box;
  height: 56px;
  display: inline-flex;
  flex-direction: row;
  background-color: ${styles.colors.white};
  border: 1px solid ${styles.colors.blueMoon};
  border-radius: 3px;
`

const TextWrapper = styled.div`
  box-sizing: border-box;
  height: 56px;
  padding: 11px 16px 11px 16px;
  width: ${props => props.width ? props.width : 'auto'}
`

const First = styled.div`
  ${styles.typography.body2};
  color: ${props => props.color ? props.color : styles.colors.mainText};
  margin-bottom: 3px;
`

const Second = styled.div`
  ${styles.typography.smallText};
  color: ${styles.colors.secondaryText};
`

const IconWrapper = styled.div`
  padding: 7px 0 8px 8px;
`

const RigthAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px 6px 8px;
`

export default function SmallPane({firstRow, firstRowColor, secondRow, left, right, textSectionWidth}) {
  return <Pane>
    {left && <IconWrapper>{left}</IconWrapper>}
    <TextWrapper width={textSectionWidth}>
      <First color={firstRowColor}>{firstRow}</First>
      <Second>{secondRow}</Second>
    </TextWrapper>
    {right && <RigthAreaWrapper>{right}</RigthAreaWrapper>}
  </Pane>
}
