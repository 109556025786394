import {ContractState} from 'bitcannery-core'
import {SecretState} from '../constants'
import {SECRET_DRAFT_ID} from '../utils'

export function connectionState(state) {
  return {
    isInitializing: state.isInitializing,
    account: state.account,
    netId: state.netId,
    connectionError: state.connectionError,
  }
}

export function secrets(state) {
  if (!state.contracts) {
    return state.contracts
  }

  let seenIds = {}

  const contracts = state.contracts.map(contract => {
    seenIds[contract.id] = true
    const localData = state.localDataByContractId[contract.id] || {}
    const ephemeralData = state.ephemeralDataByContractId[contract.id] || {}
    const data = {...localData, ...ephemeralData}
    const secretState = getSecretState(contract, data)
    return {...contract, localData: data, state: secretState}
  })

  Object.keys(state.localDataByContractId).forEach(id => {
    if (!seenIds[id] && id !== SECRET_DRAFT_ID) {
      contracts.push({
        id,
        state: SecretState.Deploying,
        localData: state.localDataByContractId[id]
      })
    }
  })

  contracts.sort((a, b) => (a.localData.createdAt | 0) - (b.localData.createdAt | 0))

  return contracts
}

export function getDraftSecretLocalData(state) {
  return state.localDataByContractId[SECRET_DRAFT_ID] || {}
}

export function secretWithId(state, id) {
  if (!state.contracts) {
    return undefined
  }

  const contract = state.contracts.find(contract => contract.id === id)
  let localData = state.localDataByContractId[id]

  if (!contract && !localData) {
    return undefined
  }

  localData = localData || {}

  const ephemeralData = state.ephemeralDataByContractId[id] || {}
  const data = {...localData, ...ephemeralData}

  return {
    ...contract,
    id,
    state: contract ? getSecretState(contract, data) : SecretState.Deploying,
    localData: data,
  }
}

function getSecretState(contract, data) {
  return contract.state === ContractState.CallForKeepers && data.activated
    ? SecretState.Activating
    : contract.isCheckInMissed
      ? SecretState.CallForKeys
      : SecretState.fromContractState(contract.state)
}
