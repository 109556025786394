import React from 'react'

const UpDownIcon = () => (
    <svg width="10px" height="6px" viewBox="0 0 10 6" >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g transform="translate(-91.000000, -74.000000)" stroke="#8D9CAD" strokeWidth="2">
          <g transform="translate(24.000000, 62.000000)">
          <polyline id="Shape" transform="translate(72.000000, 15.000000) scale(1, -1) translate(-72.000000, -15.000000) " points="68 13 72 17 76 13"></polyline>
          </g>
        </g>
      </g>
    </svg>
)

export default UpDownIcon
