import styled, {keyframes} from 'styled-components'

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  display: inline-block;
  width: 35px;
  height: 35px;

  &:after {
    content: " ";
    display: block;
    width: 35px;
    height: 35px;
    margin: 1px;
    border-radius: 50%;
    border: 4px solid #bbb;
    border-color: #bbb transparent #bbb transparent;
    animation: ${rotateAnimation} 1.2s linear infinite;
  }
`

export default Spinner
