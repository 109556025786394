import {ContractState} from 'bitcannery-core'

export const SecretState = {
  Deploying: 'DEPLOYING',
  CallForKeepers: 'CALL_FOR_KEEPERS',
  Activating: 'ACTIVATING',
  Active: 'ACTIVE',
  CallForKeys: 'CALL_FOR_KEYS',
  Cancelled: 'CANCELLED',
}

SecretState.fromContractState = (contractState) => {
  switch (contractState) {
    case ContractState.CallForKeepers: return SecretState.CallForKeepers
    case ContractState.Active: return SecretState.Active
    case ContractState.CallForKeys: return SecretState.CallForKeys
    case ContractState.Cancelled: return SecretState.Cancelled
  }
  throw new Error(`unexpected contract state: ${contractState}`)
}

export const DecryptionErrorCodes = {
  'CONTRACT_DOES_NOT_EXIST': 'CONTRACT_DOES_NOT_EXIST',
  'NOT_READY_FOR_DECRYPTION': 'NOT_READY_FOR_DECRYPTION',
  'NO_KEEPER_KEYS': 'NO_KEEPER_KEYS',
  'INVALID_PRIVATE_KEY': 'INVALID_PRIVATE_KEY',
  'NOT_ENOUGH_KEY_PARTS': 'NOT_ENOUGH_KEY_PARTS',
  'LIKELY_INVALID_PRIVATE_KEY': 'LIKELY_INVALID_PRIVATE_KEY',
}
