import React from 'react'
import {useSelector} from 'react-redux'

import {sel} from '../redux'

import NoWalletWidget from './no-wallet-widget'
import Spinner from './spinner'

export default function({children, containerClassName}) {
  const connState = useSelector(sel.connectionState)
  return <div className={containerClassName}>
    {connState.isInitializing
    ? <Spinner className='spinner'/>
    : !connState.connectionError && connState.account
      ? children
      : <NoWalletWidget connectionError={connState.connectionError} />}
    </div>
}
