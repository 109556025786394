import React, { useState } from 'react'
import styled from 'styled-components'

import {encryption} from 'bitcannery-core'

import Hero from './hero'
import Lead from './lead'
import Link from './link'
import Button from './button'
import styles from './styles'

const MainLead = styled(Lead)`
  margin-bottom: 32px;
`

const KeyLabelContainer = styled.div`
  min-height: 72px;
  border-radius: 3px;
  border: solid 1px #e8eefe;
  background-color: #fafbfc;
  margin-top: 16px;
  padding: 12px 80px 12px 16px;
  border-radius: 3px;
  width: 100%;
  overflow-wrap: break-word;
`

const KeyLabel = styled.div`
  ${styles.typography.label1};
  color: #8c9caf;
  user-select: none;
  margin-bottom: 8px;
`

const Key = styled.div`
  color: #282b43;
  ${styles.typography.body2};
`

const GenerateKeysText = styled.span`
  color: #00c48c;
  cursor: pointer;
  font-weight: 500;
`

const ActionRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 32px;
`

const ActionsLabel = styled.div`
  flex: 1;
`
const ActionsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`
const ActionsSpacer = styled.div`
  width: 16px;
`

export default function() {
  const defaultKeys = {privateKey: '', publicKey: ''}
  const [{privateKey, publicKey}, setKeyPair] = useState(defaultKeys)
  const privateKeyIsDefault = defaultKeys.privateKey === privateKey
  const defaultCopyLabel = 'Copy public key'
  const copiedCopyLabel = 'Copied!'
  const [copyLabel, setCopyLabel] = useState(defaultCopyLabel)

  function generateKeys() {
    const {privateKey, publicKey} = encryption.makeEllipticKeyPair()
    setKeyPair({privateKey, publicKey})
  }

  function copyKeyToClipboard() {
    navigator.clipboard.writeText(publicKey)
    setCopyLabel(copiedCopyLabel)
    setTimeout(_ => setCopyLabel(defaultCopyLabel), 500)
  }

  return <div className='form-wrapper'>
      <Hero>Generate Key Pair</Hero>
      <MainLead>
        <p>
          The form below generates ECC key pair. You'll need one to get the secret from BitCannery user.
          Private key is meant to be your own property, so make sure no one looks over your shoulder.
          Open <Link href='/generate-key'>this page</Link> in private browser tab, disconnect from the internet,
          and <GenerateKeysText onClick={generateKeys}>generate keys</GenerateKeysText>.
        </p>
        <p>
          The private key is required to decrypt the secret, so save to some safe space.
          The public key is required to encrypt the secret, so only you could read it. Copy the public key and send to the person sharing secret.
        </p>
      </MainLead>
      <KeyLabelContainer>
        <KeyLabel>ECC Private Key</KeyLabel>
        <Key>{privateKey}</Key>
      </KeyLabelContainer>
      <KeyLabelContainer style={{minHeight: '90px'}}>
        <KeyLabel>ECC Public Key</KeyLabel>
        <Key>{publicKey}</Key>
      </KeyLabelContainer>
      <ActionRow>
        <ActionsLabel>
          <Lead>The key generation works offline and doesn't send anything away</Lead>
        </ActionsLabel>
        <ActionsContainer>
          <Button width='160px' onClick={copyKeyToClipboard} disabled={privateKeyIsDefault}>{copyLabel}</Button>
          <ActionsSpacer/>
          <Button width='160px' onClick={generateKeys} mode='generate-key'>Generate keys</Button>
        </ActionsContainer>
      </ActionRow>
    </div>
}
