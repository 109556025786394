import React from 'react'
import {Switch} from 'react-router'
import {Route} from 'react-router-dom'

import '../../styles/fonts.css'
import '../../styles/main.css'
import '../../styles/normalize.css'
import '../../styles/layout.css'

import Header from './header'
import SecretsList from './secrets-list'
import LoadingScreen from './loading-screen'
import CreateNewSecret from './create-new-secret'
import ActivateSecret from './activate-secret'
import Decrypt from './decrypt'
import GenerateKey from './generate-key'

export default function App() {
  return (
      <div className='app'>
        <Header/>
        <div className='content'>
          <Switch>
            <Route path='/generate-key' component={GenerateKey}/>
            <Route path='/decrypt' >
              <LoadingScreen containerClassName='form-wrapper'>
                <Decrypt />
              </LoadingScreen>
            </Route>
            <Route path='/new-secret'>
              <LoadingScreen containerClassName='form-wrapper'>
                <CreateNewSecret/>
              </LoadingScreen>
            </Route>
            <Route path='/secret/:secretId' children={({match})=>(
              <LoadingScreen containerClassName='form-wrapper'>
                <ActivateSecret match={match}/>
              </LoadingScreen>
            )}/>
            <Route path='/' children={({history})=>(
              <LoadingScreen containerClassName='secret-list-wrapper'>
                <SecretsList history={history}/>
              </LoadingScreen>
            )}/>
          </Switch>
        </div>
      </div>
  )
}
