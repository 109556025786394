// WARN: code in this file is dependent on layout of data structures in CryptoLegacy.sol
// contract, so these two files need to be kept in sync.

import BigNumber from 'bignumber.js'

import {addressIsZero} from './utils/address'

export const ContractState = {
  CallForKeepers: 0,
  Active: 1,
  CallForKeys: 2,
  Cancelled: 3,
}

ContractState.stringify = stateToString

export function stateToString(number) {
  const name = ['CallForKeepers', 'Active', 'CallForKeys', 'Cancelled'][+number]
  if (!name) {
    console.error(`WARN invalid state: ${number}`)
    return 'INVALID_STATE'
  } else {
    return name
  }
}

export function assembleKeeperStruct(rawStruct) {
  return {
    publicKey: rawStruct[0],
    keyPartHash: rawStruct[1],
    keepingFee: new BigNumber(rawStruct[2]),
    balance: new BigNumber(rawStruct[3]),
    lastCheckInAt: +rawStruct[4],
    keyPartSupplied: rawStruct[5],
  }
}

export function assembleProposalStruct(rawStruct) {
  return {
    keeperAddress: rawStruct[0],
    publicKey: rawStruct[1],
    keepingFee: new BigNumber(rawStruct[2]),
  }
}

export function assembleEncryptedDataStruct(rawStruct) {
  return {
    encryptedData: rawStruct[0],
    aesCounter: rawStruct[1],
    dataHash: rawStruct[2],
    shareLength: +rawStruct[3],
    // suppliedKeyParts: rawStruct[4],
    // encoding bytes[] is not supported by current version of Solidity
  }
}

export async function describeContract(contract, reliableKeeperCheckInPeriodMult = 100) {
  const desc = await contract.describe()

  const proposals = desc.proposals.map(proposal => ({
    keeperAddress: proposal.keeperAddress,
    publicKey: proposal.publicKey,
    keepingFee: new BigNumber(proposal.keepingFee),
  }))

  const keeperReliabilityThreshold =
    getUnixTimestamp() -
    (+desc.checkInInterval) * (reliableKeeperCheckInPeriodMult / 100)

  let numReliableKeepers = 0

  const keepers = desc.keepers.map(keeper => {
    const lastCheckInAt = +keeper.lastCheckInAt
    const isReliable = lastCheckInAt > keeperReliabilityThreshold
    if (isReliable) {
      ++numReliableKeepers
    }
    return {
      keeperAddress: keeper.keeperAddress,
      balance: new BigNumber(keeper.balance),
      keyPartSupplied: keeper.keyPartSupplied,
      lastCheckInAt,
      isReliable,
    }
  })

  return {
    state: +desc.state,
    checkInInterval: +desc.checkInInterval,
    checkInPrice: new BigNumber(desc.checkInPrice),
    lastOwnerCheckInAt: +desc.lastOwnerCheckInAt,
    proposals: proposals,
    keepers: keepers,
    numKeepers: keepers.length,
    numReliableKeepers: numReliableKeepers,
  }
}

export async function getActiveKeeperAddresses(contract) {
  const numKeepers = (await contract.getNumKeepers()).toNumber()
  const promises = Array(numKeepers)
    .fill(0)
    .map((_, i) => contract.activeKeepersAddresses(i))
  return Promise.all(promises)
}

export async function getActiveKeepers(contract, keeperAddresses) {
  const rawStructs = await Promise.all(keeperAddresses.map(addr => contract.activeKeepers(addr)))
  return rawStructs.map(assembleKeeperStruct)
}

export async function fetchKeeperProposals(contract) {
  const numProposals = await contract.getNumProposals()
  const promises = new Array(+numProposals).fill(0).map((_, i) => contract.keeperProposals(i))
  return (await Promise.all(promises)).map(rawProposal => assembleProposalStruct(rawProposal))
}

export async function fetchEncryptedKeyPartsChunks(contract) {
  const numChunks = await contract.getNumEncryptedKeyPartsChunks()
  const promises = new Array(+numChunks)
    .fill(0)
    .map((_, i) => contract.getEncryptedKeyPartsChunk(i))
  return await Promise.all(promises)
}

let contractsByAddress = {}

export async function getBitCanneryContractWithId(connection, id) {
  const address = await connection.registry.getContractAddress(id)
  if (addressIsZero(address)) {
    throw new Error(`cannot find contract with id '${id}'`)
  }
  // Cache contracts to prevent memory leak in web3/truffle-contract, see:
  // https://github.com/ethereum/web3.js/issues/1648#issuecomment-501472899
  // https://github.com/trufflesuite/truffle/issues/1942
  return contractsByAddress[address] || (
    contractsByAddress[address] = await connection.BitCanneryContract.at(address)
  )
}

export async function fetchOwnerContracts(connection, ownerAddress) {
  const [ids, {timestamp: pendingBlockTimestamp}] = await Promise.all([
    fetchOwnerContractIds(connection, ownerAddress),
    connection.web3.eth.getBlock('pending'),
  ])

  return await Promise.all(ids.map(async id => {
    const contract = await getBitCanneryContractWithId(connection, id)
    const desc = await describeContract(contract, 100)

    const isCheckInMissed =
      desc.state === ContractState.CallForKeys || (
        desc.state === ContractState.Active &&
        pendingBlockTimestamp - desc.lastOwnerCheckInAt >= desc.checkInInterval)

    return {id, address: contract.address, isCheckInMissed, ...desc}
  }))
}

export async function fetchOwnerContractIds(connection, ownerAddress) {
  const numContracts = await connection.registry.getNumContractsByOwner(ownerAddress)
  const promises = new Array(+numContracts)
    .fill(0)
    .map((_, i) => connection.registry.getContractByOwner(ownerAddress, i))
  return (await Promise.all(promises)).map(rawContract => rawContract.toString())
}

export async function getContractSecretData(connection, id) {
  const address = await connection.registry.getContractAddress(id)

  const contract = contractsByAddress[address] || (
    contractsByAddress[address] = await connection.BitCanneryContract.at(address))

  const [state, encryptedDataRaw, suppliedKeyPartsCount, keepersCount] = await Promise.all([
    contract.state(),
    contract.encryptedData(),
    contract.getNumSuppliedKeyParts(),
    contract.getNumKeepers(),
  ])

  return {
    state: +state,
    suppliedKeyPartsCount: +suppliedKeyPartsCount,
    keepersCount: +keepersCount,
    encryptedData: assembleEncryptedDataStruct(encryptedDataRaw),
  }
}

export async function getSuppliedKeyParts(connection, id, keyPartsCount) {
  const address = await connection.registry.getContractAddress(id)

  const contract = contractsByAddress[address] || (
    contractsByAddress[address] = await connection.BitCanneryContract.at(address))

  const promises = new Array(+keyPartsCount)
                        .fill(0)
                        .map((_, i) => contract.getSuppliedKeyPart(i))

  const suppliedKeyParts = await Promise.all(promises)

  return suppliedKeyParts
}

function getUnixTimestamp() {
  return Math.floor(Date.now() / 1000)
}
