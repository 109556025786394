import React from 'react'
import {NetworkIds, supportedNetworkIds} from 'bitcannery-core'
import styled, {css} from 'styled-components'

import {ConnectionErrorCodes} from '../utils'

import NoWallet from './img/no-wallet-picture'
import NetNames from './utils/network-names'


const supportedNetworkNames = supportedNetworkIds.map(id => NetNames[id])

const HeaderText = {
  [ConnectionErrorCodes.NO_ETHEREUM_CLIENT]: 'No Ethereum wallet found',
  [ConnectionErrorCodes.WRONG_NETWORK]: 'You are on the wrong network',
}

const DescriptionText = {
  [ConnectionErrorCodes.NO_ETHEREUM_CLIENT]: 'Please install MetaMask or use Brave browser.',
  [ConnectionErrorCodes.WRONG_NETWORK]: (
    `Please switch to one of the following Ethereum networks ` +
    `in your wallet: ${supportedNetworkNames.join(', ')}.`
  ),
}

const h3Typography = css`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.2px;
`

const H3 = styled.h3`
  ${h3Typography}
  color: #282B43;
  text-align: center;
  margin-bottom: 8px;
`

const Wrapper = styled.div`
  width: 280px;
  margin: 0 auto;
`

const Desc = styled.div`
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.2px;
`

const NoWalletWidget = ({connectionError}) => (
  <>
    <Wrapper className='no-wallet-wrapper'>
      <NoWallet />
    </Wrapper>
    <H3>{HeaderText[connectionError] || 'Wallet Access Required'}</H3>
    <Desc>{DescriptionText[connectionError] || 'Press Connect and allow access to your wallet.'}</Desc>
  </>
)

export default NoWalletWidget
