import {call, takeEvery, put} from 'redux-saga/effects'
import {checkIn} from 'bitcannery-core'

import * as Actions from '../actions'

export default function* checkInSaga() {
  yield takeEvery(Actions.checkIn.type, performCheckin)
}

function* performCheckin({contractId}) {
  try {
    yield call(checkIn, contractId)
    console.log(`checked in`)
  } catch (err) {
    // TODO: replace with a proper UI
    window.alert(`Failed to check in: ${getErrorMessage(err)}`)
    console.log(`check-in failed:`, err.stack)
  } finally {
    yield put(Actions.checkInFinished(contractId))
  }
}

function getErrorMessage(err) {
  return /Internal JSON-RPC error/.test(err.message)
    ? 'failed to send transaction'
    : err.message
}
