import {spawn} from 'redux-saga/effects'

import connectSaga from './connect-saga'
import contractsSaga from './contracts-saga'
import deploySaga from './deploy-saga'
import activateSaga from './activate-saga'
import checkInSaga from './checkin-saga'
import localDataSaga from './local-data-saga'
import readSecretSage from './read-secret-saga'

export default function* rootSaga() {
  yield spawn(connectSaga)
  yield spawn(contractsSaga)
  yield spawn(deploySaga)
  yield spawn(activateSaga)
  yield spawn(checkInSaga)
  yield spawn(localDataSaga)
  yield spawn(readSecretSage)
}
