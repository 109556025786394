connect.type = 'CONNECT'

export function connect() {
  return {type: connect.type}
}

setConfig.type = 'SET_CONFIG'

export function setConfig(account, netId, connectionError) {
  return {type: setConfig.type, account, netId, connectionError}
}

setContracts.type = 'SET_CONTRACTS'

export function setContracts(contracts) {
  return {type: setContracts.type, contracts}
}

localDataFetched.type = 'LOCAL_DATA_FETCHED'

export function localDataFetched(localData) {
  return {type: localDataFetched.type, localData}
}

deployContract.type = 'DEPLOY_CONTRACT'

export function deployContract({
  id = getRandomId(),
  description,
  secret,
  recipientPublicKey,
  checkInInterval,
  numKeepers,
}) {
  return {
    type: deployContract.type,
    contract: {id, description, secret, recipientPublicKey, checkInInterval, numKeepers},
    timestamp: Math.floor(Date.now() / 1000),
  }
}

updateContractData.type = 'UPDATE_CONTRACT_DATA'

export function updateContractData(
  contractId,
  {description, secret, recipientPublicKey, numKeepers, checkInInterval}
) {
  return {
    type: updateContractData.type,
    contractId,
    newData: {description, secret, recipientPublicKey, numKeepers, checkInInterval},
  }
}

activateContract.type = 'ACTIVATE_CONTRACT'

export function activateContract(contractId) {
  return {type: activateContract.type, contractId}
}

activateContractFailed.type = 'ACTIVATE_CONTRACT_FAILED'

export function activateContractFailed(contractId, error) {
  return {type: activateContractFailed.type, contractId, error}
}

checkIn.type = 'CHECKIN'

export function checkIn(contractId) {
  return {type: checkIn.type, contractId}
}

checkInFinished.type = 'CHECKIN_FINISHED'

export function checkInFinished(contractId) {
  return {type: checkInFinished.type, contractId}
}

readSecret.type = 'READ_SECRET'

export function readSecret(secretId, privateKey) {
  return {type: readSecret.type, secretId, privateKey}
}

readSecretSuccess.type = 'READ_SECRET_SUCCESS'

export function readSecretSuccess(secretId, secretText) {
  return {type: readSecretSuccess.type, secretId, secretText}
}

readSecretError.type = 'READ_SECRET_ERROR'

export function readSecretError(secretId, error) {
  return {type: readSecretError.type, secretId, error}
}

cleanCurrentSecret.type = 'CLEAN_CURRENT_SECRET'

export function cleanCurrentSecret() {
  return {type: cleanCurrentSecret.type}
}

import dockerNames from 'docker-names'

function getRandomId() {
  return dockerNames.getRandomName() + '_' + Math.floor(Math.random() * 99 + 1)
}
