import {call, take, takeEvery} from 'redux-saga/effects'
import {deployContract} from 'bitcannery-core'

import history from '../../history'
import * as Actions from '../actions'

export default function* deploySaga() {
  yield takeEvery(Actions.deployContract.type, performDeploy)
}

function* performDeploy({contract}) {
  // TODO: handle errors
  // TODO: obtain and save TX id (reimplement Truffle method)

  const addr = yield call(deployContract, contract.id, contract.checkInInterval)

  console.log(`contract deployed, address:`, addr)

  let storedContract

  while (!storedContract) {
    const {contracts} = yield take(Actions.setContracts.type)
    storedContract = contracts.find(c => c.id === contract.id)
  }

  if (history.location.pathname === '/new-secret') {
    history.push(`/secret/${contract.id}`)
  }
}
