import {call, select, takeEvery, put} from 'redux-saga/effects'

import {getNumKeepersRequiredForRecovery, activateContract} from 'bitcannery-core'

import {SecretState} from '../../constants'
import history from '../../history'

import * as Actions from '../actions'
import * as sel from '../selectors'

export default function* activateSaga() {
  yield takeEvery(Actions.activateContract.type, handleActivateContract)
}

function* handleActivateContract({contractId}) {
  const secret = yield select(sel.secretWithId, contractId)

  if (!secret || (secret.state !== SecretState.CallForKeepers && secret.state !== SecretState.Activating)) {
    console.error(`Failed to activate secret '${contractId}': not found or invalid state`)
    return
  }

  const {numKeepers, secret: secretText, recipientPublicKey} = secret.localData
  const numKeepersToRecover = getNumKeepersRequiredForRecovery(numKeepers)

  try {
    yield call(
      activateContract,
      contractId,
      secretText,
      numKeepers,
      numKeepersToRecover,
      recipientPublicKey,
    )

    yield put(Actions.updateContractData(contractId, {secret: '', recipientPublicKey: ''}))

    if (history.location.pathname === `/secret/${contractId}`) {
      history.push(`/`)
    }
  } catch (err) {
    console.log(`activation failed:`, err.stack)
    yield put(Actions.activateContractFailed(contractId, err))
  }
}
