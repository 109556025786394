import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'


const Wrapper = styled.div`
  margin-top: 5px;
  width: 80px;
  height: 4px;
  border-radius: 4px;
  background-color: #E7EDF4;
`

const Progress = styled.div`
  width: ${props => `${props.percent * 80}px`};
  height: 4px;
  border-radius: 4px;
  background-color: ${props => props.progressColor};
`

export class ProgressBar extends Component {
  static propTypes = {
    value: PropTypes.number,
    total: PropTypes.number
  }

  render() {
    const {
      value,
      total,
      color,
    } = this.props

    let percent
    if (total === 0) {
      percent = 0
    } else {
      percent  = value / total
    }

    let progressColor
    if (percent >= 0 && percent < 0.5) {
      progressColor = '#FD667E'
    } else if (percent >= 0.5 && percent < 0.7) {
      progressColor = '#FCCC74'
    } else {
      progressColor = '#38C487'
    }

    if (color) {
      progressColor = color
    }

    return (
      <Wrapper>
        <Progress percent={percent} progressColor={progressColor} />
      </Wrapper>
    )
  }
}

export default ProgressBar
