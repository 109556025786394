import React from 'react'

const NoWallet = () => (
  <svg version="1.1" x="0px" y="0px"
	     viewBox="0 0 280 200" width="280px" height="200px">
    <g>
    	<g id="illustrations-expend">
    		<path fill="#E6E8EF" d="M44.7,154.3c-6.4-2.6-25.6-20.9-27.4-53.3C16.7,91.3,28,64,43.4,51.8c19.2-15.2,44.3-4.9,54.7,2.7
    			c10.2,7.5,15.5,28.9,4.7,39.7c-7.4,7.4-29.1,6.7-26.8,41.6C77.3,154.8,53.8,158,44.7,154.3z"/>
    		<path fill="#E6E8EF" d="M108.8,101.9c6-0.6,19.9-0.8,30.9-9.9c15.9-13.1,18.5-45.8,49.4-54.7c10.5-3,30.5-4,47.4,8.5
    			c21,15.6,29.1,65-4.8,73.3c-19.9,4.9-21.8,0.1-31.1,1.2c-27.4,3.3-22.2,22.6-34.2,41.1c-15.6,24.1-68.4,8.1-79-15.5
    			C78,124.7,85.4,104,108.8,101.9z"/>
    		<polygon fill="#FFFFFF" points="2.1,92.7 22.7,141.2 62.2,106.2 28,117.6 		"/>
    		<polygon fill="#FFFFFF" points="63.8,99.6 45.2,41.5 3.4,86 29.5,111 		"/>
    		<polygon stroke="#8789A0" strokeLinecap="round" strokeLinejoin="round" fill="none" points="45.2,41.5 29.5,111 3.4,86 		"/>
    		<polygon stroke="#8789A0" strokeLinecap="round" strokeLinejoin="round" fill="none" points="22.7,141.2 28,117.6 2.1,92.7 		"/>
    		<path fill="#8789A0" d="M29.5,111.5h-0.1c-0.3-0.1-0.4-0.3-0.4-0.6l7-31.1L3.5,86.5c-0.3,0.1-0.5-0.1-0.6-0.4c0,0,0,0,0,0
    			c-0.1-0.3,0.1-0.5,0.4-0.6c0,0,0,0,0,0l33.3-6.9c0.2,0,0.3,0,0.5,0.1c0.1,0.1,0.2,0.3,0.1,0.5L30,111.1
    			C29.9,111.3,29.8,111.5,29.5,111.5z"/>
    		<path fill="#8789A0" d="M29.5,111.5c-0.3,0-0.5-0.2-0.5-0.5c0,0,0-0.1,0-0.1l15.7-69.4c0-0.2,0.2-0.4,0.5-0.4l0,0
    			c0.2,0,0.4,0.1,0.5,0.3l18.7,58c0.1,0.3-0.1,0.5-0.3,0.6l-34.3,11.4C29.6,111.5,29.6,111.5,29.5,111.5z M45.3,43.5l-15.1,66.7
    			l33-10.9L45.3,43.5z"/>
    		<path fill="#8789A0" d="M22.7,141.7c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.3-0.3-0.2-0.5l5.3-23.5c0-0.2,0.2-0.3,0.3-0.4L62,105.8
    			c0.2-0.1,0.5,0,0.6,0.2c0.1,0.2,0.1,0.5-0.1,0.6L23,141.6C22.9,141.6,22.8,141.7,22.7,141.7z M28.4,118l-4.9,21.7L60,107.5
    			L28.4,118z"/>
    		<path fill="#8789A0" d="M29.5,111.5h-0.1c-0.3-0.1-0.4-0.3-0.4-0.6L36.2,79c0-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0.1l27.1,20.5
    			c0.2,0.2,0.3,0.5,0.1,0.7c-0.2,0.2-0.5,0.3-0.7,0.1L37,80l-7,31.1C29.9,111.3,29.8,111.5,29.5,111.5z"/>
    		<path fill="#FFFFFF" d="M204.7,60.7c-2.3-3,4.3-11.8,14.7-19.8s20.7-12,23-9l36.2,47.3c2.3,3-4.3,11.8-14.7,19.8s-20.7,12-23,9
    			L204.7,60.7z"/>
    		<path fill="#FFFFFF" d="M272.1,70.7c1.1,1.4,0.1,4.2-2.3,7.6c-0.2,0.3-0.7,0.4-1,0.1c-0.1,0-0.1-0.1-0.1-0.1l-22.2-29.1
    			c-0.2-0.3-0.2-0.6,0-0.9c2.6-3.5,3.6-6.4,2.5-7.9L272.1,70.7z"/>
    		<path fill="#FFFFFF" d="M234.4,99.5c2.3,3,12.6-1.1,23-9c3-2.3,5.8-4.7,8.4-7.4c0.2-0.3,0.3-0.6,0-0.9l-22.3-29.1
    			c-0.2-0.3-0.7-0.4-1-0.1c0,0,0,0-0.1,0.1c-2.6,2.6-5.3,5.1-8.2,7.3c-10.4,8-20.7,12-23,9L234.4,99.5z"/>
    		<path fill="#8789A0" d="M207.3,62.2c-1.1,0.1-2.2-0.3-3-1.2c-2.8-3.6,5.4-13.3,14.8-20.5S240,28,242.8,31.6s-5.4,13.3-14.8,20.5
    			c-4.5,3.5-9.4,6.5-14.6,8.7C211.4,61.6,209.4,62.1,207.3,62.2z M239.8,31.4c-3.8,0-11.4,3.3-20,9.9c-11,8.5-16.5,16.7-14.6,19.1
    			c0.9,1.2,3.9,1,7.9-0.6c5.2-2.2,10-5.1,14.4-8.5c11-8.5,16.5-16.7,14.6-19.1C241.4,31.6,240.6,31.3,239.8,31.4z"/>
    		<path fill="#8789A0" d="M243.5,109.5c-1.1,0.1-2.2-0.3-3-1.2L204.3,61l0.8-0.6l36.2,47.3c1.8,2.4,11.2-0.7,22.3-9.1
    			c4.5-3.3,8.5-7.3,12-11.7c2.6-3.4,3.5-6.2,2.6-7.4L242,32.2l0.8-0.6L279,78.9c1.3,1.7,0.4,4.6-2.6,8.6c-3.5,4.5-7.6,8.5-12.2,11.9
    			C256.8,105,248.3,109.5,243.5,109.5z"/>
    		<path fill="#8789A0" d="M207.9,60.2c-0.6,0.1-1.3-0.2-1.7-0.7c-1.1-1.4,1-4.5,2.9-6.9c6.9-7.7,15.1-14,24.3-18.6
    			c2.8-1.3,6.3-2.5,7.4-1.1c1.1,1.4-1,4.5-3,6.9c-3.4,3.9-7.2,7.4-11.3,10.4c-4,3.2-8.4,5.9-13,8.2C211.8,59.4,209.9,60,207.9,60.2z
    			 M239.2,32.9c-1.9,0.2-3.8,0.8-5.5,1.7c-4.6,2.3-8.9,5-12.9,8.1c-4.1,3-7.9,6.5-11.2,10.3c-2.5,3-3.6,5.3-2.9,6.2l0,0
    			c0.7,0.9,3.2,0.4,6.8-1.2c4.6-2.2,8.9-5,12.9-8.1c4.1-3,7.9-6.5,11.2-10.4c2.5-3,3.6-5.3,2.9-6.2C240.2,33,239.7,32.8,239.2,32.9
    			L239.2,32.9z"/>
    		<path fill="#8789A0" d="M269.2,79.1c-0.4,0-0.8-0.2-1-0.5l-22.1-29.1c-0.3-0.4-0.3-1,0-1.5c2.5-3.4,3.4-6.1,2.5-7.3l0.8-0.6
    			c1.2,1.6,0.4,4.6-2.5,8.5c-0.1,0.1-0.1,0.2,0,0.3L269.1,78c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c2.4-3.3,3.2-5.8,2.3-7
    			l0.8-0.6c1.2,1.6,0.4,4.4-2.3,8.2C270,78.9,269.6,79.1,269.2,79.1L269.2,79.1z"/>
    		<path fill="#8789A0" d="M237.1,101c-1.1,0.1-2.2-0.3-3-1.2l0.8-0.6c0.9,1.2,3.9,1,7.9-0.6c5.2-2.2,10-5.1,14.4-8.6
    			c3-2.2,5.7-4.7,8.3-7.4c0.1-0.1,0.1-0.2,0-0.3l-22.2-29c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0.1c-2.6,2.6-5.4,5.1-8.3,7.3
    			c-9.5,7.2-20.9,12.6-23.7,8.9l0.8-0.6c1.8,2.4,11.2-0.7,22.3-9.1c2.9-2.2,5.6-4.6,8.2-7.2c0.3-0.3,0.6-0.4,1-0.4
    			c0.4,0,0.7,0.2,0.9,0.5l22.2,29.1c0.4,0.5,0.3,1.1-0.1,1.6c-2.6,2.7-5.4,5.2-8.4,7.5c-4.5,3.5-9.5,6.5-14.7,8.7
    			C241.1,100.4,239.1,100.9,237.1,101z"/>
    		<path fill="#FFFFFF" d="M130.5,121.1L130.5,121.1c-0.3-0.8-1.2-1.3-2.1-1l-10.9,3.5c-0.2-0.9-0.5-1.7-0.6-2s-0.4-1-0.7-1.9l11-3.5
    			c0.8-0.3,1.3-1.2,1-2l0,0c-0.3-0.8-1.2-1.3-2-1.1l-11.1,3.6l0,0c-0.4-0.9-0.8-1.8-1.2-2.6c-0.5-0.2-1-0.4-1.5-0.6
    			c-1.1-0.4-2.2-0.6-3.4-0.7l0,0c-1.6,0.1-3.1,0.3-4.6,0.8c-5.8,1.9-9.7,6.4-10.3,11.2l0,0c-0.3,0-0.6,0-0.9,0.1
    			c-1.2,0.4-1.9,1.8-1.8,3.4v-0.1c-3.6,0.8-4.9,3.9-5.7,5.7c-0.8-1.6-2.3-4.2-5.7-3.7c-2.8,0.4-3.4,3.6-3.9,6.4
    			c-0.2,1.2-0.5,2.4-0.9,3.5c-0.5-1-0.8-2.1-1.1-3.1c-0.7-2.7-1.6-6-4.5-7.2c-4.4-1.8-6.6,1.5-8,3.5s-1.9,2.5-2.9,2.3
    			c-1.3-0.2-4.4-3.4-6.9-6c-4.6-4.7-9.7-10.1-14.7-10.1c-0.4,0-0.8,0.9-0.8,2.1s0.3,2.1,0.8,2.1c3.2,0,8,4.9,11.8,8.9
    			c3.6,3.8,6.5,6.7,9.2,7.2c3.7,0.6,5.7-2.2,6.9-4c1.6-2.3,1.9-2.4,3-2s1.6,2.6,2,4.4c0.7,2.6,1.6,6.3,5,6.3c0.2,0,0.4,0,0.6,0
    			c3.2-0.4,3.8-4,4.4-6.9c0.2-1.1,0.4-2.1,0.8-3.1c0.3,0.1,0.6,0.4,1.2,1.9c0.6,1.2,1.6,3.5,4,3c1.9-0.4,2.7-2.2,3.3-3.7
    			c0.8-1.9,1.7-3.2,3.3-3.4l-0.1-0.3c0.7,1,1.9,1.5,3.1,1.3c0.3-0.1,0.6-0.3,0.8-0.5c3.3,3.5,9.2,4.9,14.9,3.1
    			c1.5-0.5,2.9-1.1,4.2-2c0.9-0.8,1.6-1.6,2.3-2.6c0.3-0.4,0.6-0.9,0.9-1.4c-0.2-0.9-0.4-1.9-0.6-2.8l11.1-3.6
    			c0.9-0.2,1.4-1.1,1.2-2C130.5,121.2,130.5,121.1,130.5,121.1z"/>
    		<path fill="#8789A0" d="M75.3,144.7c-3.8,0-4.8-4-5.5-6.7c-0.4-1.4-1-3.8-1.7-4.1s-0.9-0.4-2.4,1.8c-1.3,1.9-3.4,4.9-7.4,4.3
    			c-2.8-0.5-5.7-3.5-9.4-7.3c-3.5-3.6-8.4-8.7-11.4-8.7c-0.9,0-1.3-1.3-1.3-2.6s0.4-2.5,1.2-2.6l0,0c5.2,0,10.5,5.4,15.1,10.3
    			c2.2,2.2,5.4,5.6,6.6,5.8c0.7,0.1,1-0.1,2.4-2.1s3.8-5.6,8.6-3.6c3.1,1.3,4,4.7,4.8,7.5c0.1,0.5,0.3,1.2,0.5,1.9
    			c0.2-0.8,0.4-1.6,0.5-2.2c0.5-2.8,1.2-6.4,4.3-6.8c2.3-0.4,4.7,0.9,5.7,3c0.9-1.9,2.3-4.3,5.6-5c0.3-0.1,0.5,0.1,0.6,0.4
    			s-0.1,0.5-0.4,0.6c-3.4,0.7-4.5,3.6-5.3,5.4c-0.1,0.2-0.2,0.3-0.4,0.3l0,0c-0.2,0-0.4-0.1-0.4-0.3c-0.9-1.8-2.2-3.8-5.1-3.4
    			c-2.4,0.4-3,3.2-3.5,6c-0.2,1.2-0.5,2.5-1,3.6c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.5-1-0.9-2.1-1.1-3.3
    			c-0.7-2.6-1.5-5.8-4.2-6.9c-4-1.6-6,1.3-7.4,3.3s-2,2.7-3.4,2.5s-3.8-2.7-7.1-6.1c-4.5-4.7-9.6-9.9-14.4-9.9
    			c-0.2,0.5-0.3,1-0.3,1.5c-0.1,0.6,0.1,1.1,0.4,1.6l0,0c3.3,0,8.1,5,12,9c3.6,3.7,6.4,6.6,8.9,7c3.4,0.6,5.2-2,6.4-3.8
    			c1.6-2.3,2.2-2.8,3.6-2.2c1.2,0.5,1.8,2.8,2.3,4.8c0.7,2.7,1.7,6.3,5.1,5.9c2.8-0.3,3.4-3.6,3.9-6.5c0.2-1.1,0.4-2.2,0.8-3.2
    			c0.1-0.2,0.3-0.3,0.6-0.3c0.5,0.1,0.9,0.6,1.6,2.1s1.6,3.1,3.4,2.7c1.7-0.3,2.3-2,2.9-3.4c0.7-1.6,1.7-3.4,3.7-3.8
    			c0.3,0,0.5,0.1,0.6,0.4c0,0,0,0,0,0c0,0.3-0.1,0.5-0.4,0.6c0,0,0,0,0,0c-1.2,0.2-2.1,1.1-2.9,3.1c-0.6,1.5-1.5,3.5-3.7,4
    			c-2.8,0.6-4-2.1-4.6-3.3c-0.1-0.4-0.3-0.8-0.5-1.2c-0.2,0.7-0.4,1.6-0.5,2.3c-0.6,2.9-1.3,6.9-4.8,7.4
    			C75.7,144.7,75.5,144.7,75.3,144.7z"/>
    		<path fill="#8789A0" d="M106.4,136.8c-1.9,0-3.8-0.3-5.6-1c-3.2-1.1-5.7-3.7-6.8-6.8c-2-6.3,2.6-13.5,10.2-16
    			c1.5-0.5,3.1-0.8,4.7-0.8c0.3,0,0.5,0.2,0.5,0.4c1,7.2,3.3,14.2,6.7,20.7c0.1,0.2,0,0.5-0.2,0.6
    			C113.2,135.8,109.8,136.8,106.4,136.8L106.4,136.8z M108.6,113.1c-1.4,0.1-2.7,0.4-4,0.8c-7.2,2.3-11.5,8.9-9.6,14.7
    			c1,2.9,3.3,5.2,6.2,6.2c3.3,1.2,6.9,1.3,10.2,0.1c1.3-0.4,2.5-1,3.7-1.7C111.9,126.9,109.7,120.1,108.6,113.1L108.6,113.1z"/>
    		<path fill="#8789A0" d="M115.8,133.9c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0.1-0.3,0.2-0.4c0.8-0.7,1.6-1.6,2.2-2.5c0.3-0.4,0.5-0.8,0.7-1.2
    			c-0.2-0.9-0.4-1.8-0.6-2.6c-0.1-0.3,0.1-0.5,0.4-0.6c0,0,0,0,0,0c0.3-0.1,0.5,0.1,0.6,0.4c0.2,0.9,0.4,1.9,0.6,2.9
    			c0,0.1,0,0.2-0.1,0.3c-0.3,0.5-0.6,1-0.9,1.4c-0.7,1-1.5,1.9-2.4,2.6C116,133.9,115.9,133.9,115.8,133.9z"/>
    		<path fill="#8789A0" d="M117.5,124c-0.2,0-0.4-0.1-0.5-0.4c-0.2-0.6-0.4-1.3-0.6-1.9s-0.4-1.2-0.6-1.8c-0.1-0.3,0.1-0.5,0.3-0.6
    			c0.3-0.1,0.5,0,0.6,0.3c0.2,0.6,0.4,1.2,0.6,1.8s0.4,1.3,0.6,2c0.1,0.3-0.1,0.5-0.3,0.6L117.5,124z"/>
    		<path fill="#8789A0" d="M115.1,117c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.8-0.7-1.6-1.1-2.4c-0.4-0.2-0.9-0.4-1.3-0.5
    			c-1.1-0.3-2.1-0.6-3.2-0.7c-0.3,0-0.5-0.3-0.5-0.5c0-0.3,0.3-0.5,0.5-0.5c1.8,0.1,3.5,0.6,5.1,1.4c0.1,0.1,0.2,0.1,0.2,0.2
    			c0.4,0.8,0.8,1.7,1.2,2.6c0.1,0.3,0,0.5-0.3,0.7c0,0,0,0,0,0C115.2,117,115.2,117,115.1,117z"/>
    		<path fill="#8789A0" d="M116.2,127.9c-0.6,0-1.1-0.9-1.4-1.7s-0.4-2.1,0.3-2.3l13.2-4.3c1.2-0.2,2.3,0.6,2.4,1.8
    			c0.1,0.9-0.3,1.8-1.1,2.2l-13.2,4.3L116.2,127.9z M115.5,124.7c0,0.4,0,0.8,0.2,1.1c0.1,0.4,0.3,0.7,0.5,1l13.1-4.2
    			c0.6-0.2,0.9-0.8,0.7-1.4l0,0c-0.1-0.3-0.3-0.5-0.6-0.7c-0.3-0.1-0.6-0.2-0.9-0.1L115.5,124.7z"/>
    		<path fill="#8789A0" d="M113.9,120.8c-0.1,0-0.2,0-0.3-0.1c-0.6-0.3-1-1.3-1.1-1.6c-0.2-0.6-0.3-1.3-0.1-2c0.1-0.2,0.2-0.3,0.4-0.4
    			l13.2-4.2c1.1-0.4,2.3,0.2,2.7,1.4c0,0,0,0,0,0l0,0c0.4,1.1-0.3,2.3-1.4,2.7l-13.2,4.3C114.1,120.8,114,120.8,113.9,120.8z
    			 M113.3,117.7c0,0.4,0,0.8,0.2,1.1c0.1,0.4,0.3,0.7,0.5,1l13-4.2c0.6-0.2,0.9-0.8,0.7-1.4l0,0c-0.2-0.6-0.8-0.9-1.4-0.7
    			c0,0,0,0,0,0L113.3,117.7z"/>
    		<path fill="#8789A0" d="M95.2,133.5c-0.7,0-1.4-0.3-2-0.8c-0.9-0.7-1.6-1.7-1.9-2.9c-0.4-1.1-0.4-2.3-0.1-3.5c0.3-1,1-1.8,2-2.2
    			c0.4-0.1,0.8-0.2,1.1-0.1l-0.1,1c-0.2,0-0.5,0-0.7,0.1c-0.7,0.3-1.1,0.8-1.3,1.5c-0.5,1.9,0.2,4,1.7,5.2c0.5,0.5,1.3,0.7,1.9,0.5
    			c0.2-0.1,0.4-0.2,0.6-0.4l0.7,0.7c-0.3,0.3-0.6,0.5-1,0.6C95.8,133.4,95.5,133.5,95.2,133.5z"/>
    		<path fill="#8789A0" d="M106.4,133.3c-1.3,0-2.7-0.2-3.9-0.5l0.1-0.5c2.7,0.7,5.5,0.7,8.2-0.1l0.1,0.5
    			C109.4,133.1,107.9,133.3,106.4,133.3z"/>
    		<path fill="#FFFFFF" d="M235.2,85.1c-0.7-0.9-1.4-1.5-1.8-1.2c-3.9,2.8-7.4,6.2-10.5,9.9c-3,3.5-5.6,6.6-11.3,9.8s-9.5,2.8-11.2,2.1
    			c-1.2-0.4-2.2-1.2-2.7-2.4c-0.9-2.6-3.7-10.3-9.5-8.3c-4.9,1.7-3.8,7.4-3.4,9.3c0,0.1,0.1,0.3,0.1,0.5c0.7,3.2,0.2,3.7-0.3,3.8
    			c-0.1,0-0.2,0-0.2,0c-0.5-0.7-0.9-1.5-1.1-2.3c-0.6-1.7-1.4-3.6-3-4.8c-2.6-1.9-5.4-0.8-8.1,0.2c-1.5,0.6-3.1,1-4.6,1.3l0,0
    			c-0.7-1.1-1.9-1.6-3.2-1.3c-0.3,0.1-0.6,0.3-0.8,0.5l0,0c-3.3-3.6-9.1-4.9-14.9-3.1c-1.5,0.5-2.9,1.1-4.2,2c0,0,0,0.1,0,0.2v-0.1
    			c-0.9,0.8-1.6,1.6-2.3,2.6c-0.3,0.4-0.6,0.9-0.8,1.4c1.1,5.4,2.8,10.6,5.1,15.5c0.5,0.2,1,0.5,1.5,0.6c1.1,0.3,2.2,0.6,3.3,0.7
    			l0,0c1.6-0.1,3.1-0.3,4.6-0.8c5.8-1.9,9.7-6.4,10.3-11.2c0.3,0,0.6,0,0.9-0.1c1.2-0.4,1.9-1.9,1.8-3.6l0.2,0.6
    			c1.6-0.3,3.1-0.8,4.6-1.4c2.4-0.9,3.5-1.2,4.3-0.7c0.7,0.8,1.3,1.8,1.5,2.9c0.7,1.8,1.9,5.1,5.1,5c0.4,0,0.7-0.1,1.1-0.2
    			c2.3-0.6,4.7-2.5,3.4-8.8l-0.1-0.3c-0.8-4.1,0.2-4.4,0.7-4.6c1.7-0.6,3.4,3.4,4.3,5.8c0.9,2.4,2.7,4.2,5.1,5
    			c2.4,0.9,7.4,1.7,14.6-2.4c4.8-2.7,9-6.4,12.4-10.7c2.8-3.5,6.1-6.6,9.7-9.3C236.1,86.9,235.9,86,235.2,85.1z"/>
    		<path fill="#8789A0" d="M184.3,113.3c-3.4,0-4.7-3.4-5.4-5.3c-0.3-1-0.7-1.9-1.4-2.7c-0.6-0.4-1.5-0.1-3.9,0.7
    			c-1.5,0.6-3.1,1.1-4.7,1.4c-0.3,0-0.5-0.1-0.6-0.4c0-0.3,0.1-0.5,0.4-0.6c1.5-0.3,3-0.8,4.5-1.4c2.4-0.9,3.8-1.3,4.8-0.6
    			c0.8,0.5,1.2,1.8,1.7,3.1c0.6,1.8,1.7,4.7,4.5,4.7h0.1c0.3,0,0.7-0.1,1-0.1c3-0.7,4-3.5,3-8.2l-0.1-0.4c-0.8-4,0-4.8,1-5.2
    			c1.6-0.6,3.3,1.5,4.9,6.1c0.9,2.2,2.7,3.8,4.9,4.6c2.3,0.8,7.2,1.7,14.2-2.4c4.7-2.7,8.9-6.3,12.3-10.6c2.8-3.5,6.1-6.6,9.8-9.3
    			c-0.1-0.5-0.3-1-0.7-1.4c-0.3-0.5-0.7-0.8-1.1-1.1c-3.9,2.8-7.3,6.1-10.3,9.8c-3,3.6-5.7,6.6-11.5,10c-6,3.4-9.8,2.8-11.6,2.2
    			c-1.3-0.4-2.4-1.4-3-2.7c-0.9-2.4-3.5-9.8-8.9-8c-4,1.4-3.7,5.6-3.1,8.7l0.1,0.4c0.6,2.9,0.4,4.2-0.7,4.5h-0.3
    			c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.8-1-1.3-2.6s-1.3-3.5-2.8-4.6c-2.4-1.7-5-0.8-7.7,0.2c-1.5,0.6-3.1,1.1-4.8,1.3
    			c-0.3,0-0.5-0.2-0.6-0.4c0-0.3,0.2-0.5,0.4-0.6c1.6-0.3,3.1-0.7,4.5-1.3c2.7-1,5.8-2.1,8.6-0.1c1.8,1.3,2.5,3.3,3.2,5
    			c0.2,0.7,0.5,1.3,0.8,1.9c0.2-0.2,0.4-1.1-0.1-3.2c0-0.2-0.1-0.4-0.1-0.5c-0.4-2-1.5-8,3.7-9.8c6.3-2.2,9.2,6,10.1,8.6
    			c0.5,1,1.4,1.8,2.4,2.1c1.6,0.6,5.1,1.1,10.8-2.1s8.2-6.2,11.2-9.7c3.1-3.8,6.6-7.2,10.6-10c0.2-0.1,0.4-0.2,0.6-0.1
    			c0.7,0.1,1.4,1,1.8,1.5c0.5,0.6,0.8,1.4,0.9,2.2c0,0.2-0.1,0.5-0.3,0.6c-3.6,2.6-6.9,5.7-9.6,9.2c-3.4,4.4-7.7,8.1-12.6,10.8
    			c-7.4,4.2-12.6,3.4-15.1,2.5c-2.5-0.8-4.5-2.7-5.5-5.2c-1.3-3.7-2.7-5.8-3.6-5.5c-0.3,0.1-1.1,0.4-0.4,4l0.1,0.3
    			c1.6,7-1.7,8.8-3.7,9.3c-0.4,0.1-0.8,0.2-1.2,0.2L184.3,113.3z M233.7,84.2L233.7,84.2z"/>
    		<path fill="#8789A0" d="M151.1,122.5c-0.2,0-0.5-0.2-0.5-0.4c-0.5-3.6-1.3-7.2-2.5-10.6c-1.1-3.5-2.5-6.8-4.2-10.1
    			c-0.1-0.2-0.1-0.5,0.2-0.7c1.3-0.9,2.8-1.6,4.3-2.1c7.7-2.5,15.6,0.7,17.7,7s-2.6,13.5-10.2,16
    			C154.3,122.2,152.7,122.5,151.1,122.5L151.1,122.5z M145,101.4c1.6,3.2,3,6.4,4.1,9.8c1.1,3.4,1.9,6.8,2.4,10.3
    			c1.4-0.1,2.7-0.3,4-0.8c7.2-2.3,11.5-8.9,9.6-14.7s-9.2-8.7-16.4-6.4C147.4,100.1,146.2,100.6,145,101.4z"/>
    		<path fill="#8789A0" d="M151.2,122.5L151.2,122.5c-1.2-0.1-2.4-0.3-3.5-0.7c-0.5-0.2-1.1-0.4-1.6-0.6c-0.1-0.1-0.2-0.1-0.2-0.2
    			c-2.4-5-4.1-10.2-5.2-15.7c0-0.1,0-0.2,0-0.3c0.3-0.5,0.6-1,0.9-1.5c0.7-1,1.5-1.9,2.4-2.7c0.2-0.2,0.5-0.2,0.7,0c0,0,0,0,0,0
    			c0.2,0.2,0.2,0.5,0,0.7c0,0,0,0,0,0c-0.8,0.7-1.6,1.6-2.2,2.5c-0.2,0.3-0.5,0.8-0.7,1.2c0.5,2.6,1.2,5.2,2,7.7
    			c0.9,2.5,1.9,5,3,7.4c0.5,0.2,0.9,0.4,1.3,0.5c1.1,0.3,2.2,0.6,3.3,0.7c0.3,0,0.5,0.2,0.5,0.5S151.5,122.5,151.2,122.5
    			L151.2,122.5z"/>
    		<path fill="#8789A0" d="M166.3,110.6h-0.4l0.1-1c0.2,0,0.5,0,0.7-0.1c1.3-0.4,1.8-2.4,1.2-4.4s-2.2-3.3-3.5-2.8
    			c-0.2,0.1-0.4,0.2-0.6,0.4l-0.7-0.7c0.3-0.3,0.6-0.5,1-0.6c1.8-0.6,4,1,4.8,3.5s0,5.1-1.8,5.6
    			C166.8,110.5,166.5,110.6,166.3,110.6z"/>
    		<path fill="#8789A0" d="M154.6,118.6l-0.2-0.5c2.6-0.9,4.9-2.6,6.7-4.7l0.4,0.3C159.7,116,157.3,117.7,154.6,118.6z"/>
    		<path fill="#8789A0" d="M144.9,110.9c-0.1,0-0.2,0-0.4-0.1c-0.5-0.4-0.9-1-1.1-1.7c-0.3-1-0.4-2.1,0.3-2.3s1.3,0.7,1.6,1.7
    			c0.1,0.4,0.2,0.9,0.2,1.3c0,0.8-0.4,1-0.6,1C145,110.8,144.9,110.9,144.9,110.9z M144.2,108.3c0,0.2,0.1,0.4,0.1,0.6
    			c0.1,0.2,0.1,0.4,0.2,0.6c0-0.2-0.1-0.4-0.1-0.6C144.4,108.6,144.3,108.4,144.2,108.3z"/>
    		<path fill="#8789A0" d="M147.1,117.9c-0.6,0-1.1-0.9-1.4-1.8s-0.4-2.1,0.3-2.3l0,0c0.7-0.2,1.3,0.7,1.6,1.7s0.4,2.1-0.3,2.3
    			C147.3,117.9,147.2,117.9,147.1,117.9z M146.5,115.3c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.5c0-0.2-0.1-0.4-0.1-0.6
    			C146.7,115.6,146.6,115.5,146.5,115.3z"/>
    		<polygon fill="#FFFFFF" points="59,159.8 59,159.8 59,159.8 		"/>
    		<circle fill="#F96A82" cx="127.3" cy="77.5" r="12.9"/>
    		<path fill="#FFFFFF" d="M132.8,81.9l-3.4-4.2l4.2-3.4c0.7-0.5,0.7-1.5,0.2-2.1c-0.5-0.7-1.5-0.7-2.1-0.2l0,0l-4.2,3.4l-3.4-4.2
    			c-0.5-0.7-1.5-0.7-2.1-0.2c-0.7,0.5-0.7,1.5-0.2,2.1l3.4,4.2l-4.1,3.4c-0.7,0.4-1,1.3-0.6,2.1c0.4,0.7,1.3,1,2.1,0.6
    			c0.1-0.1,0.3-0.2,0.4-0.3l4.2-3.4l3.4,4.2c0.5,0.6,1.5,0.7,2.1,0.2C133.3,83.4,133.4,82.5,132.8,81.9L132.8,81.9z"/>
    		<polygon fill="#FFFFFF" points="104.3,53.6 110.3,45.5 110.5,40.4 99.7,40.6 95.5,38.7 91.4,36.8 84.5,28.5 80.8,32 78.5,41.8
    			73,47.6 71.3,55.2 79.6,55.9 83.4,58.7 85.9,59.8 88.3,60.9 92.9,62 98.9,67.8 103.5,61.5 		"/>
    		<polygon fill="#8789A0" points="85.3,43.5 85.3,43.5 78.4,42.1 78.6,41.6 85.3,43 91,41 84.3,28.6 84.7,28.4 91.7,41.2 		"/>
    		<path fill="#8789A0" d="M98.9,68.2l-6.2-6.1L90.1,55l-3.5-1.6l-7,2.7L71,55.4l1.8-7.9v0l5.4-5.7l2.3-9.8l4-3.7l7.1,8.5l8.1,3.7
    			l11-0.2l-0.2,5.4v0.1l-5.9,8.1l-0.8,7.9v0.1L98.9,68.2z M93.1,61.9l5.7,5.6l4.4-6l0.8-7.9l6-8.1l0.2-4.8l-10.6,0.2L91.2,37
    			l-6.8-8.1L81,32.1l-2.3,9.8v0l-5.4,5.7L71.6,55l8,0.7l7.1-2.7l3.8,1.8v0.1L93.1,61.9z"/>
    		<path fill="#8789A0" d="M87.3,51L83,47.8l4-0.1L87.3,51z M84.5,48.2l2.3,1.8l-0.2-1.7L84.5,48.2z"/>
    		<polygon fill="#8789A0" points="92.9,62.2 88.3,61.2 83.3,58.9 79.4,56.1 79.7,55.7 83.5,58.5 88.5,60.7 93,61.8 		"/>
    		<path fill="#8789A0" d="M96.8,54.1L91.5,53l2.7-2L96.8,54.1z M92.7,52.7l2.7,0.6l-1.3-1.6L92.7,52.7z"/>
    		<polygon fill="#8789A0" points="89.6,58 84.8,55.8 86.5,53 86.9,53.3 85.5,55.6 89.3,57.3 90.1,54.8 90.6,54.9 		"/>
    		<polygon fill="#8789A0" points="104.2,53.8 98.6,49.6 96.1,43.2 110.4,40.2 110.5,40.7 96.8,43.6 99,49.2 104.4,53.4 		"/>
    		<polygon fill="#FFFFFF" points="181.6,128.2 188.2,126.4 193.3,129.5 196.4,128 201.9,131.2 201.5,134.2 204.1,137.4 203.8,159.9
    			193.6,172.2 178.6,166.8 166.9,147.5 167.5,143.4 165.6,141.1 168.7,135.6 172.2,135.3 175,130 		"/>
    		<path fill="#8789A0" d="M183.9,143.9l-10-1.3l5.4-2l4.3,0.4L183.9,143.9z M175.9,142.4l7.4,0.9l-0.2-1.8l-3.8-0.4L175.9,142.4z"/>
    		<path fill="#8789A0" d="M193.6,172.5l-15.2-5.5v-0.1l-11.8-19.4l0.6-4.1l-1.9-2.4l3.3-5.8l3.4-0.3l2.8-5.3l13.5-3.7l5.1,3.1l3.1-1.5
    			l5.7,3.4l-0.4,3l2.6,3.2v0.1L204,160L193.6,172.5z M178.7,166.6l14.8,5.3l10-12.1l0.3-22.4l-2.6-3.2l0.4-2.9l-5.2-3.1l-3.1,1.5
    			l-5.1-3.2l-13,3.5l-2.8,5.3l-3.4,0.3l-3,5.3l1.8,2.3l-0.6,4.1L178.7,166.6z"/>
    		<path fill="#8789A0" d="M191.1,163.2l-12.6-6l0.1-5l-8-5.2l3.1-9.5l5.4-0.4l4.1-2.6l4.8,0.1l4.9-2.4l0.1,0.1l7.4,6.5l-4.3,8.6
    			l2.6,4.3L191.1,163.2z M179.1,156.8l11.9,5.7l7.4-10.9l-2.6-4.2l4.2-8.4l-7-6.1l-4.7,2.3l-4.8-0.1l-4.1,2.6l-5.1,0.4l-2.9,8.8
    			l7.9,5.2L179.1,156.8z"/>
    		<path fill="#8789A0" d="M188.1,152.2l-5.2-2.2l1.1-1.5l3-0.6l2.8-1l1.7,0.8L188.1,152.2z M183.7,149.8l4.3,1.8l2.8-3.8l-1-0.4l-2.7,1
    			l-2.8,0.5L183.7,149.8z"/>
    		<path fill="#8789A0" d="M187.6,142.9l-1.2-2.6l3.6-2.6l5.6-1L187.6,142.9z M187,140.5l0.8,1.6l5.9-4.5l-3.6,0.6L187,140.5z"/>
    		<circle fill="#BBBFC4" cx="124.6" cy="137.1" r="1.8"/>
    		<circle fill="#BBBFC4" cx="129.1" cy="131.3" r="1.3"/>
    		<circle fill="#BBBFC4" cx="146.9" cy="130" r="2.1"/>
    		<circle fill="#BBBFC4" cx="133.6" cy="95.9" r="2.1"/>
    		<circle fill="#BBBFC4" cx="132.8" cy="102.9" r="1.3"/>
    	</g>
    </g>
  </svg>
)

export default NoWallet
