import React from 'react'

import AccountButton from './account-button'
import Logo from './logo'
import Link from './link'

export default function Header() {
  return <div className='header'>
    <Logo />
    <div className='links'>
      <Link href='/'>Secrets</Link>
      <Link href='/decrypt'>Decrypt</Link>
      <Link href='/generate-key'>Generate&nbsp;key</Link>
    </div>
    <div className='account'>
      <AccountButton/>
    </div>
  </div>
}
